import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { seo } from '../../config/seo';


export default function Seo() {

    const [metaTitle, setMetaTitle] = useState(seo.default.title)
    const [metaDescription, setMetaDescription] = useState(seo.default.description)

    let location = useLocation();

    useEffect(() => {
        let path = location.pathname

        if(path.includes('/entry') || path.includes('/case') || path.includes('/blog')) {
            //salto seo
        }
        else {
            if(path !== '') {
                if(seo.hasOwnProperty(path)) {
                    setMetaTitle(seo[path].title)
                    setMetaDescription(seo[path].description)
                }
                else {
                    setMetaTitle(seo.default.title)
                    setMetaDescription(seo.default.description)
                }
            }
            else {
                setMetaTitle(seo.default.title)
                setMetaDescription(seo.default.description)
            }
        }
        
    }, [location]);



    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
        </Helmet>
    );
};