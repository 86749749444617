import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import moment from 'moment';
import { Container, Alert, Spinner, Image, Pagination } from 'react-bootstrap';
import { ApiManager } from '../../core/api-manager';
import { Helmet } from 'react-helmet';
import { settings } from '../../config/settings';


export default function Blog() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { p } = useParams();
    const page = parseInt(p)

    const [data, setData] = useState(null);
    const [totalPages, setTotalPages] = useState(0);


    useEffect(() => {
        init()
    }, [page]);

    
    async function init() {
        let body = {
            page: page - 1
        }
        var response = await ApiManager.sendRequest('/public/get_blog', body)
        console.warn(response)
        if(response.success === 1) {
            setData(response.body)
            setTotalPages(response.pages)
        }
        else {
            setErrors('Errore. Riprova più tardi.')
        }
    }

    function changePage(p) {
        navigate('/blog/' + p)
    }

    function goToPrevPage() {
        if(page > 1) {
            changePage(page-1)
        }
    }
    function goToNextPage() {
        if(page < totalPages) {
            changePage(page+1)
        }
    }
    function goToFirstPage() {
        if(page !== 1) {
            changePage(1)
        }
    }
    function goToLastPage() {
        if(page !== totalPages) {
            changePage(totalPages)
        }
    }


    if (data !== null) {
        return (
            <>
                <Helmet>
                    <title>{'Blog - ' + page} | Gruppo Yec</title>
                </Helmet>

                <Container fluid style={{ marginTop: 70, backgroundColor: '#f4f4f4' }} className='p-0'>

                    <div className='blog-container mt-5 mb-5'>
                        <div className='blog--grid'>
                            {data.map((item, i) => {
                                let date = moment(item.insertDatetime).format('DD/MM/YYYY');
                                let friendly_url = "/entry/" + item.id + "-" + item.friendlyurl;
                                if (item.image !== null) {
                                    var image = encodeURI(settings.ckdPath + 'upload/blog/' + item.image + '.jpg');
                                }
                                if (item.tags !== null && item.tags !== '') {
                                    var tags = item.tags.split(',');
                                }
                                else {
                                    var tags = [];
                                }
                                return(
                                    <div key={i} className='entry'>
                                        <table className='entry'>
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <div className='preview'>
                                                            {item.image !== null ? (
                                                                <Link to={friendly_url}>
                                                                    <Image src={image} alt={item.title} height={400} width={600} />
                                                                </Link>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className='tags'>
                                                            {tags.map((tag, j) => {
                                                                let tagTrim = tag.trim()
                                                                return (
                                                                    <span key={j}>{tagTrim}</span>
                                                                )
                                                            })}
                                                        </div>
                                                        <h3><Link to={friendly_url}>{item.title}</Link></h3>
                                                        <p className='date'>{date}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })}
                        </div>
                    </div>  
                    
                    <div className="d-flex justify-content-center">
                        <Pagination>
                            <Pagination.First className={page == 1 ? 'disabled' : ''} onClick={goToFirstPage} />
                            <Pagination.Prev className={page <= 1 ? 'disabled' : ''} onClick={goToPrevPage} />
                            <Pagination.Item>
                                {page}
                            </Pagination.Item>
                            <Pagination.Next className={page >= totalPages ? 'disabled' : ''} onClick={goToNextPage} />
                            <Pagination.Last className={page >= totalPages ? 'disabled' : ''} onClick={goToLastPage} />
                        </Pagination>
                    </div>
                </Container>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="secondary" />
                    <p className='text-secondary'>Loading</p>
                </div>
            </Container>
        )
    }
};