import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import CallToAction from '../../components/CallToAction';
import adobe from '../../assets/servizi/ecommerce/adobe.svg';
import shopify from '../../assets/servizi/ecommerce/shopify.svg';
import magento from '../../assets/servizi/ecommerce/magento.svg';
import salesforce from '../../assets/servizi/ecommerce/salesforce.svg';



export default function EcommerceB2b() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h1>Siamo <span style={{ color: '#418BFF' }}>tra le pochissime agenzie in Italia ad avere competenze certificate</span> per le attività di vendita business-to-business.</h1>
                    <Row className='mt-5 me-auto ms-auto' style={{maxWidth:700, filter:'grayscale(1)'}}>
                        <Col xs={6} md={3} className='p-4'>
                            <Image src={magento} height={60} />
                            <br/>
                            <span className='small text-secondary'>Magento</span>
                        </Col>
                        <Col xs={6} md={3} className='p-4'>
                            <Image src={shopify} height={60} />
                            <br/>
                            <span className='small text-secondary'>Shopify Plus</span>
                        </Col>
                        <Col xs={6} md={3} className='p-4'>
                            <Image src={adobe} height={60} />
                            <br/>
                            <span className='small text-secondary'>Adobe Commerce</span>
                        </Col>
                        <Col xs={6} md={3} className='p-4'>
                            <Image src={salesforce} height={60} />
                            <br/>
                            <span className='small text-secondary'>Salesforce</span>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid style={{ backgroundColor: '#1A2446' }}>
                <Container fluid='md' className='mt-5 pt-4 pb-3'>
                    <Row className='mt-5'>
                        <Col xs={12} lg={4} className='pe-5 pb-4 text-white'>
                            <h2>Le principali<br/><span style={{ color: '#418BFF' }}>differenze con gli e-commerce B2C</span></h2>
                        </Col>
                        <Col xs={12} lg={8} className='pe-5 pb-4 text-white'>
                            <h3>Condizioni personalizzate per ogni cliente</h3>
                            <p>Devi poter creare cataloghi personalizzati per ogni cliente, sconti esclusivi e listini prezzi su misura, in modo rapido ed efficace.</p>

                            <h3 className='mt-5'>Gestione delle offerte cliente e dei preventivi</h3>
                            <p>Devi poter gestire la creazione e l’invio di offerte personalizzate e la rapida conversione in ordini di acquisto. Il tutto gestendo un archivio documentale aggiornato…</p>

                            <h3 className='mt-5'>Massima integrazione con tutti gli strumenti</h3>
                            <p>Devi poter disporre di una piattaforma e-commerce totalmente integrata con gli altri sistemi di gestione come il CRM, l’ERP, il tool per la fatturazione, ecc.</p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid>
                <Container fluid='md' className='mt-5 pt-2 pb-3'>
                    <Row className='mt-5'>
                        <Col xs={12} lg={4} className='pe-5 pb-4'>
                            <h2><span style={{ color: '#418BFF' }}>Ti garantiamo supporto</span> in tutte le seguenti fasi</h2>
                        </Col>
                        <Col xs={12} lg={8} className='pe-5 pb-4'>
                            <h3>Consulenza IT</h3>
                            <p>Per esempio, scegliere la piattaforma adatta alle tue specifiche esigenze è di fondamentale importanza per la buona riuscita del tuo progetto di vendita online. Banalmente la scelta della piattaforma da utilizzare influirà poi sulla scalabilità del portale e-commerce, sulle funzionalità, sulle Integrazioni e sulla flessibilità della piattaforma, sui costi e su tanti altri aspetti rilevanti. Altri aspetti chiave che non possono essere trascurati sono la logistica e gli aspetti legali e fiscali. Sviluppando piattaforme e-commerce per brand sia nazionali che internazionali, siamo costantemente aggiornati circa le nuove direttive fiscali, i regimi IVA, le policy GDPR e tutti gli aspetti più delicati di fisco e tassazione.</p>
                            
                            <h3 className='mt-5'>Design</h3>
                            <p>Durante una sessione di acquisto online, gli utenti preferiscono percorsi chiari e semplici che in pochi step li portino dalla scelta del prodotto all’acquisto, in totale sicurezza.
                            <br/>In sintesi, la UI/UX si concentra sull'aspetto e sull'esperienza di navigazione; la strategia creativa definisce come promuovere i tuoi prodotti; la produzione di contenuti crea il materiale per la promozione e la produzione di foto e video fornisce contenuti visivi di alta qualità per il tuo e-commerce. Tutti questi elementi collaborano per creare un'esperienza coinvolgente e persuasiva per i tuoi clienti.</p>

                            <h3 className='mt-5'>Strategia</h3>
                            <p>È il piano strategico che definisce come un'azienda intende utilizzare il canale di vendita online per raggiungere i propri obiettivi commerciali. La strategia è progettata per guidare tutte le attività correlate all'e-commerce, tra cui la definizione dei budget, il marketing online, il servizio clienti, l’analisi dei dati, gli aggiornamenti, e molto altro.</p>

                            <h3 className='mt-5'>CRM & Automation</h3>
                            <p>La marketing automation in un e-commerce è la strategia e l’insieme degli strumenti tecnologici progettati per automatizzare e ottimizzare le attività di marketing e comunicazione con i clienti. Questa pratica consente di creare e offrire esperienze personalizzate per i clienti, migliorare l'efficacia del marketing e aumentare le vendite, il tutto in modo efficiente. Un esempio di quelle che possono essere le automazioni circa i messaggi sono: l’invio automatico di email di conferma dell'ordine e di spedizione; l’invio di offerte personalizzate o codici sconto in base al comportamento del cliente; l’invio di messaggi di follow-up dopo un acquisto e il recupero di carrelli abbandonati attraverso email o notifiche push.</p>

                            <h3 className='mt-5'>Gestione della piattaforma</h3>
                            <p>Si riferisce alla gestione complessiva del negozio online, che include una serie di attività e responsabilità volte a garantire il corretto funzionamento, la crescita e il successo dell'attività di vendita online. Questo ruolo chiave è essenziale per assicurare che il negozio online funzioni senza intoppi, soddisfi le esigenze dei clienti e raggiunga gli obiettivi commerciali stabiliti. Rientrano la gestione e l’aggiornamento costante dell’inventario, la gestione delle promozioni e degli ordini, il customer care, ecc.</p>

                            <h3 className='mt-5'>Business Intelligence</h3>
                            <p>Si riferisce alla raccolta, all'analisi e all'utilizzo dei dati per prendere decisioni informate e ottimizzare le operazioni e le strategie commerciali relative all’e-commerce. La BI (business intelligence) è un elemento essenziale per il successo dell'e-commerce, poiché aiuta a comprendere meglio il comportamento dei clienti, a individuare opportunità di crescita e a migliorare l'efficacia della piattaforma IT e delle strategie.</p>

                            <h3 className='mt-5'>Marketing: SEO & SEM</h3>
                            <p>Le attività di marketing per l'e-commerce sono un insieme di strategie, tattiche e azioni finalizzate a promuovere il negozio online, attrarre traffico qualificato, coinvolgere i clienti e generare le vendite. Queste attività sono essenziali per la crescita e il successo di un e-commerce, poiché aiutano a creare consapevolezza del marchio, a generare interesse nei prodotti o servizi offerti e a convertire i visitatori in clienti. Supportiamo i nostri clienti nelle attività di SEO (migliorare il posizionamento sui motori di ricerca) e SEM (generare traffico di qualità mediante attività di promozione online). </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='p-0 mt-5'>
                <Row className='p-0 m-0'>
                    <Col xs={12} lg={6} style={{ backgroundColor: '#418BFF' }} className='text-white p-5'>
                        <Row className='align-items-center'>
                            <Col xs={12} lg={6} className='text-center text-md-start'>
                                <h4>E-commerce B2C</h4>
                            </Col>
                            <Col xs={12} lg={6} className='text-center'>
                                <Link to="/service/ecommerce-b2c">
                                    <span className='button light mt-4'>Scopri di più</span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6} style={{ backgroundColor: '#1A2446' }} className='text-white p-5'>
                        <Row className='align-items-center'>
                            <Col xs={12} lg={6} className='text-center text-md-start'>
                                <h4>E-commerce B2B</h4>
                            </Col>
                            <Col xs={12} lg={6} className='text-center'>
                                <Link to="/service/ecommerce-b2b">
                                    <span className='button light mt-4'>Scopri di più</span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>


            <CallToAction />

        </>
    );
};