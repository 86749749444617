import React, { useState, useEffect } from 'react';
import { Container, Image, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { ApiManager } from '../core/api-manager';
import { settings } from '../config/settings';
import CallToAction from '../components/CallToAction';


export default function Showcase() {

    const [showcaseActive, setShowcaseActive] = useState(null)


    useEffect(() => {
        if (showcaseActive !== null) {
            getData(1, 100, showcaseActive)
        }
    }, [showcaseActive]);

    useEffect(() => {
        getData(1, 100, "")
    }, []);

    
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState('');
    async function getData(p, l, s) {
        let body = {
            page: p - 1,
            type: s
        }
        var response = await ApiManager.sendRequest('/public/get_showcase', body)
        console.warn(response)
        if(response.success === 1) {
            setData(response.body)
        }
        else {
            setErrors('Errore. Riprova più tardi.')
        }
    }



    return (
        <>
            <Container fluid className='bg-white pt-5 mb-5'>
                <Container fluid>

                    <Container className='mt-5 mb-5'>
                        <center>
                            <h1>Alcuni progetti</h1>
                            <p className='lead'>
                                Ogni giorno aiutiamo le imprese italiane a crescere e adattarsi a un mondo in continua evoluzione. Ci impegniamo a fornire soluzioni digitali su misura e di alta qualità, affinché le aziende possano ottimizzare i propri processi, migliorare l'efficienza e aumentare la competitività sul mercato.
                            </p>
                        </center>
                    </Container>


                    {data !== null && data.length > 0 ? (
                        <>
                            <Row>
                                {data.map((item, i) => {
                                    let image = encodeURI(settings.ckdPath + 'upload/showcase/' + item.image + '.jpg');
                                    let url = "/case/" + item.id;
                                    return (
                                        <Col key={i} xs={12} sm={12} md={6} className='mb-3'>
                                            <div className="showcase-item">
                                                {item.image !== null ? (
                                                    <Image src={image} alt={item.title} height={'auto'} width={'100%'} />
                                                ) : (
                                                    <></>
                                                )}
                                                <h5>{item.title}</h5>
                                                <h2>{item.description}</h2>
                                                <p>{item.subtitle}</p>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </>
                    ) : (
                        <>
                            {data === null ? (
                                <div className='text-center p-5'>
                                    <Spinner animation="border" variant="secondary" size='lg' />
                                    <p className='text-secondary'>Loading</p>
                                </div>
                            ) : (
                                <Alert variant='warning'>
                                    Nessun risultato trovato.
                                </Alert>
                            )}
                        </>
                    )}

                </Container>

            </Container>

            <CallToAction/>
            
        </>
    );
};