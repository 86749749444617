import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import notfound from '../assets/404.svg';
import { Helmet } from 'react-helmet';


export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>Page 404</title>
            </Helmet>
            <Container fluid='sm' className='mt-5 mb-5 text-secondary'>
                
                <Row className='pt-4 align-items-center'>
                    <Col xs={12} lg={6}>
                        <img src={notfound} width={'100%'} />
                    </Col>
                    <Col xs={12} lg={6}>
                        <h1>Errore.<br/>Pagina non trovata.</h1>
                        <p className='lead'>Questa pagina non esiste o è stata spostata.</p>
                        <br/>
                        <Link to="/">
                            <span className='button'>
                                Torna alla home
                            </span>
                        </Link>
                    </Col>
                </Row>

            </Container>
        </>
    );
};