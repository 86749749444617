import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


export default function RedirectSite() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/");
    }, []);


    return (
        <Container fluid='md' className='mt-5 mb-5'></Container>
    );
};