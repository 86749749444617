export const headerParticles = {
	fullScreen: { enable: false, zIndex: 0 },
	fpsLimit: 2240,
	fullScreen: { enable: false, zIndex: 0 },
	particles: {
		number: { value: 60 }, 
		color: { value: "rgba(200,200,200,0.6)" },
		links: { color: 'rgba(200,200,200,0.6)', distance: 150, enable: true, opacity: 0.5, width: 1 },
		shape: { type: "circle" }, 
		opacity: {
			value: 0.5, 
			random: true, 
			anim: { enable: true, speed: 3 }
		}, 
		size: { value: 4 }, 
		move: {
			enable: true,
			speed: 2, 
			direction: "none" 
		} 
	}, 
	interactivity: {
		events: {
			onHover: {
				enable: true, 
				mode: "repulse"
			},
			onClick: {
				enable: false, 
				mode: "push"
			}
		},
		modes: {
			repulse: {
				distance: 90, 
				duration: 0.4
			}
		}
	},
  	retina_detect: true
};

export const bohParticles = {
	fullScreen: { enable: false, zIndex: 0 },
	fpsLimit: 240,
	particles: {
		number: { value: 50 }, 
		color_random: true,
		links: { color: '#86ffe4', distance: 150, enable: true, opacity: 0.5, width: 1 },
		shape: { type: "triangle" }, 
		opacity: {
			value: 0.5, 
			random: true, 
			anim: { enable: true, speed: 3 }
		}, 
		size: { value: 8 }, 
		move: {
			enable: true,
			speed: 2, 
			direction: "right" 
		} 
	}, 
	interactivity: {
		events: {
			onHover: {
				enable: true, 
				mode: "repulse"
			},
			onClick: {
				enable: false, 
				mode: "push"
			}
		},
		modes: {
			repulse: {
				distance: 90, 
				duration: 0.4
			}
		}
	},
  	retina_detect: true
}

export const analyticaParticles = {
	fullScreen: { enable: false, zIndex: 0 },
	fpsLimit: 240,
	particles: {
		number: { value: 50 }, 
		color: { value: "#fff" },
    	links: { color: '#ffffff', distance: 150, enable: true, opacity: 0.5, width: 1 },
		shape: { type: "circle" }, 
		opacity: {
			value: 0.5, 
			random: true, 
			anim: { enable: true, speed: 3 }
		}, 
		size: { value: 3 }, 
		move: {
			enable: true,
			speed: 2, 
			direction: "right" 
		} 
	}, 
	interactivity: {
		events: {
			onHover: {
				enable: true, 
				mode: "repulse"
			},
			onClick: {
				enable: false, 
				mode: "push"
			}
		},
		modes: {
			repulse: {
				distance: 90, 
				duration: 0.4
			}
		}
	},
  	retina_detect: true
};

export const formaFarmaParticles = {
	fullScreen: { enable: false, zIndex: 0 },
	fpsLimit: 240,
	particles: {
		number: { value: 50 }, 
		color_random: true,
	  	links: { color: '#86ffe4', distance: 150, enable: true, opacity: 0.5, width: 1 },
		shape: { type: "circle" }, 
		opacity: {
			value: 0.5, 
			random: true, 
			anim: { enable: true, speed: 3 }
		}, 
		size: { value: 8 }, 
		move: {
			enable: true,
			speed: 2, 
			direction: "right" 
		} 
	}, 
	interactivity: {
		events: {
			onHover: {
				enable: true, 
				mode: "repulse"
			},
			onClick: {
				enable: false, 
				mode: "push"
			}
		},
		modes: {
			repulse: {
				distance: 90, 
				duration: 0.4
			}
		}
	},
	retina_detect: true
}

export const imageBg = {
  	fullScreen: { enable: false, zIndex: 0 },
  	particles: {
		color: {
			value: "#ff0000",
			animation: {
				enable: true,
				speed: 20,
				sync: true,
			},
		},
		lineLinked: {
			blink: false,
			color: "random",
			consent: false,
			distance: 30,
			enable: true,
			opacity: 0.3,
			width: 0.5,
		},
		move: {
			attract: {
				enable: false,
				rotate: {
				x: 600,
				y: 1200,
				},
			},
			bounce: false,
			direction: "none",
			enable: true,
			outMode: "bounce",
			random: true,
			speed: 0.5,
			straight: false,
		},
		number: {
			density: {
				enable: false,
				area: 2000,
			},
			limit: 0,
			value: 200,
		},
		opacity: {
			animation: {
				enable: true,
				minimumValue: 0.05,
				speed: 2,
				sync: false,
			},
			random: false,
			value: 1,
		},
		shape: {
			type: "circle",
		},
		size: {
			animation: {
				enable: false,
				minimumValue: 0.1,
				speed: 40,
				sync: false,
			},
			random: true,
			value: 1,
		},
  	},
  	polygon: {
		draw: {
			enable: true,
			lineColor: "rgba(255,255,255,0.2)",
			lineWidth: 0.3,
		},
		move: {
			radius: 10,
		},
		inlineArrangement: "equidistant",
		scale: 0.5,
		type: "inline",
		url: "/nomeimmagineinpublic.svg",
  	},
};
