import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Table } from 'react-bootstrap';


export default function Careers() {

    useEffect(() => {

    }, []);


    return (
        <Container fluid='md' style={{ marginTop: 70 }} className='mb-5'>

            <Container fluid='md' className='pt-5'>
                <h2>Demos</h2>

                <Table stripped>
                    <thead>
                        <tr>
                            <th>Oggetto</th>
                            <th>Link</th>
                            <th>Credenziali</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>LMS</td>
                            <td><a href='https://yec-lms-demo-user.netlify.app' target="_blank">https://yec-lms-demo-user.netlify.app</a></td>
                            <td>marco@gruppoyec.com : <i>gruppoyec</i></td>
                        </tr>
                        <tr>
                            <td>LMS ADMIN</td>
                            <td><a href='https://yec-lms-demo-admin.netlify.app' target="_blank">https://yec-lms-demo-admin.netlify.app</a></td>
                            <td>marco@gruppoyec.com : <i>gruppoyec</i></td>
                        </tr>
                        <tr>
                            <td>TIE - V1</td>
                            <td><a href='https://business.truckiteasycloud.com/' target="_blank">https://business.truckiteasycloud.com/</a></td>
                            <td>user : <i>user</i></td>
                        </tr>
                        <tr>
                            <td>TIE - V2</td>
                            <td><a href='https://tie-v2.netlify.app/' target="_blank">https://tie-v2.netlify.app/</a></td>
                            <td>user : <i>user</i></td>
                        </tr>
                        <tr>
                            <td>CRM</td>
                            <td><a href='https://yec-crm.netlify.app/' target="_blank">https://yec-crm.netlify.app/</a></td>
                            <td>user : <i>user</i></td>
                        </tr>
                        <tr>
                            <td>POLDO</td>
                            <td><a href='https://poldo.gruppoyec.com/' target="_blank">https://poldo.gruppoyec.com/</a></td>
                            <td>user : <i>user</i></td>
                        </tr>
                        <tr>
                            <td>CIRCUIT</td>
                            <td><a href='https://circuit.gruppoyec.com/' target="_blank">https://circuit.gruppoyec.com/</a></td>
                            <td>marco@gruppoyec.com : <i>gruppoyec</i></td>
                        </tr>
                    </tbody>
                </Table>
            </Container>

        </Container>
    );
};