import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import CallToAction from '../../components/CallToAction';
import icoErp from '../../assets/static/erp.svg';
import banner from '../../assets/servizi/erp/banner.jpg';



export default function Erp() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h1><span style={{ color: '#418BFF' }}>Volta pagina.</span> Digitalizza la tua azienda.</h1>
                    <p className='lead text-muted m-auto' style={{ maxWidth: 700 }}>
                        I progetti di ingegneria complessa, meglio noti come ERP (Enterprise Resource Planning), hanno l’obiettivo di digitalizzare tutte le attività interne a un’azienda. Gruppo YEC, a questo proposito, ha sviluppato un ERP cloud che dispone di diversi moduli e che può essere ampiamente customizzato in base alle specifiche esigenze di ogni azienda.
                    </p>
                </Container>
            </Container>

            <Container fluid className='bg-secondary p-0 mt-5'>
                <Image src={banner} width={'100%'} />
            </Container>

            <Container fluid='md' className='mt-5 mb-5'>
                <h2>Perché un’azienda dovrebbe adottare un ERP?</h2>
                <p>Per digitalizzare e semplificare i processi aziendali.<br />Nel dettaglio, un ERP consente di:</p>
                <ul>
                    <li>aumentare il livello di tracciabilità (ossia, per esempio, di avere ogni informazione necessaria
                        circa i materiali impiegati in un dato progetto, gli operatori che vi hanno lavorato, per quanto
                        tempo si sono dedicati al progetto, ecc.)</li>

                    <li>rendere il monitoraggio aziendale estremamente preciso garantendo massimo controllo
                        sotto il punto di vista economico e finanziario, evitando così spese inutili e, di conseguenza,
                        brutte sorprese a fine anno. Inoltre, consente di verificare ogni volta che lo si desidera lo stato
                        della produzione o di avanzamento di una specifica commessa.</li>
                </ul>
            </Container>

            <Container fluid='md' className='mt-5 mb-5'>
                <h2>Cerchi un partner esperto per lo sviluppo di un ERP per la tua azienda?</h2>
                <p>Per poter sviluppare un software gestionale cucito su misura è necessario conoscere tutti i
                    processi che il gestionale dovrà mappare e gestire. Per questo motivo, prima di cominciare con lo
                    sviluppo dell’ERP, il nostro team effettua un assessment, ossia un’analisi funzionale che consente
                    di definire nel dettaglio ogni fase del progetto esecutivo.</p>
            </Container>

            <Container fluid='md' className='mt-5 mb-5'>
                <h2>Moduli</h2>
                <p>Di seguito i moduli firmati Gruppo YEC che possono essere adottati per il proprio ERP.</p>
                <Row>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>MES</h3>
                        <p>
                            <b>Il software pensato per la rilevazione, la gestione e l’organizzazione dei dati di produzione</b>. Le funzioni includono l’avanzamento delle lavorazioni in corso, il monitoraggio dell’intero flusso produttivo, l’analisi dello sfruttamento delle attrezzature e il reporting del risultato produttivo e degli scarti.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Supervisione</h3>
                        <p>
                            <b>Il sistema di supervisione e gestione degli impianti produttivi automatizzati</b> che raccoglie in un unico punto tutte le informazioni di stato e di allarme di un impianto tecnologico, permettendo a colpo d’occhio di conoscere l’operatività di qualsiasi componente.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Interconessione</h3>
                        <p>
                            <b>Il software pensato per monitorare il corretto funzionamento e l’efficienza di macchine e impianti</b> attraverso report e notifiche che consentono l’analisi delle performance dei dispositivi con i quali vengono interconnessi.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Ordini</h3>
                        <p>
                            <b>Il portale web pensato per la gestione degli ordini a terzisti e fornitori</b> allo scopo di agevolare lo scambio di informazioni in un’ottica di supply chain automation. Permette all’azienda, o alle aziende esterne, di monitorare lo stato di avanzamento degli ordini, inserirne tutta la documentazione (disegni, etichette, etc.) e avere riscontro della sua visualizzazione e presa in carico in tempo reale.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Pianifcazione</h3>
                        <p>
                            <b>Il modulo per la valutazione efficace delle attività e delle risorse di un progetto</b> grazie al quale è possibile analizzare un piano di produzione e monitorarlo in tempo reale. Questo strumento permette di far emergere situazioni critiche quali: colli di bottiglia, sovraccarichi, inutilizzi o ritardi, rispetto dei vincoli, nonché l’indicazione del momento ottimale per l’approvvigionamento dei materiali.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Preventivi</h3>
                        <p>
                            <b>Il configuratore di preventivo per l’area commerciale che permette di elaborare una documentazione di prodotto personalizzata</b> (su vari livelli, dai semilavorati ai prodotti finiti) in base a esigenze specifiche, consentendo la configurazione di componenti, accessori e combinazioni all’interno di cataloghi anche molto complessi.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Integrazione</h3>
                        <p>
                            <b>Il modulo applicativo che permette di connettere sistemi, impianti e macchinari alla piattaforma gestionale integrata</b> o a soluzioni ERP già in uso in azienda, garantendo l’interscambiabilità dei dati e creando un flusso strutturato di informazioni.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Qualità</h3>
                        <p>
                            <b>L’applicativo rivolto alla gestione della qualità dei prodotti e dei processi</b>. Grazie a questo modulo si possono individuare le criticità che portano a segnalazioni o dichiarazione di non conformità di un prodotto o servizio per poter intervenire tempestivamente e limitare gli sprechi di risorse nelle funzioni coinvolte, siano esse interne o esterne all’azienda.
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Magazzino</h3>
                        <p>
                            <b>Il software che risponde alle esigenze di logistica delle aziende attraverso un’ampia gamma di funzionalità dedicate alla gestione dei magazzini</b>. La funzionalità principale di questo modulo è quella di controllare le movimentazioni e l’immagazzinamento dei materiali gestendo prelievi (scarichi), ricevimenti (carichi), spostamenti e rifornimenti interni di merce (produzioni).
                        </p>
                    </Col>
                    <Col md={12} lg={6} className='pt-5 pb-5'>
                        <h3>Manutenzioni</h3>
                        <p>
                            <b>Il modulo che utilizza la struttura della piattaforma YEC ERP per gestire i piani di manutenzione degli asset aziendali</b>. Tali manutenzioni possono essere sia preventive pianificate che non pianificate. Grazie a un set di report dedicati riesce a rappresentare situazioni puntuali o simulate e permette l’utilizzo condiviso di singole funzioni anche a operatori esterni all’azienda.
                        </p>
                    </Col>
                </Row>
            </Container>


            <CallToAction />

        </>
    );
};