export const language = {
    lorem: {
        text1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    defaults: {
        noResults: 'Nessun risultato trovato.',
        notFound: 'Pagina non trovata.',
        view: 'Visualizza',
        edit: 'Modifica',
        delete: 'Elimina',
        details: 'Dettagli',
        close: 'Chiudi',
        open: 'Apri',
        error: 'Errore',
        save: 'Salva',
        search: 'Cerca',
        create: 'Crea nuovo',
        resultsPerPage: 'Risultati per pagina',
        more: 'Vedi di più',
        discoverMore: 'Scopri di più'
    },
    header: {
        about: 'About us',
        method: 'Metodo',
        services: 'Servizi',
        manifesto: 'Manifesto',
        showcase: 'Showcase',
        blog: 'News',
        people: 'Persone',
        careers: 'Careers',
        contact: 'Contatti'
    },
    footer: {
        title1: 'Company',
        title2: 'Other companies',
        title3: 'Follow Us',
        title4: 'Help',
        title5: 'For support',
        title6: 'For media inquiries',
        title7: 'To get in touch',
        logoCaption: 'Sviluppiamo software facili da usare e belli da vedere'
    },
    home: {
        title1: 'Trasformiamo i bisogni delle aziende in soluzioni digitali altamente tecnologiche e performanti.',
        title2: '',
        text1: "Crediamo fortemente nel potere della tecnologia per favorire la crescita aziendale, permettendo l'ottimizzazione dei processi, l'incremento della produttività e l'apertura di nuove opportunità.",
        text2: "La nostra esperienza è a tua disposizione per traghettare la tua azienda verso il futuro.",
        title3: 'I nostri servizi',
        software: 'Piattaforme Web',
        softwareText: 'Diamo vita al tuo <b>progetto digitale</b> partendo dall\'idea per arrivare al prodotto finito. E lo facciamo attraverso il linguaggio più adatto alle tue esigenze.',
        industry: 'Gestionali',
        industryText: 'Interconnettiamo gli <b>strumenti aziendali in ottica 4.0</b> attraverso sistemi personalizzati per l\'interfacciamento, la gestione e l\'analisi dei dati, e consentiamo di accedere a incentivi e sgravi fiscali.',
        blockchain: 'Blockchain',
        blockchainText: 'Supportiamo i clienti nell\'analisi e nell\'implementazione di soluzioni <b>Blockchain & DLT</b>. Offriamo a pubblici e privati i seguenti servizi: consulenza tecnologica, sviluppo blockchain, applicazioni distribuite (Dapps), sviluppo Smart Contracts.',
        ecommerce: 'E-commerce',
        ecommerceText: 'Sviluppiamo e gestiamo piattaforme e-commerce attraverso le migliori tecnologie. Pianifichiamo e supportiamo tutte le fasi del processo: dalla <b>strategy</b> allo <b>sviluppo</b>, dalla gestione al marketing, dagli aspetti legali e fiscali alla logistica.',
        app: 'Applicazioni',
        appText: 'Sviluppiamo applicazioni mobile per il mondo <b>iOS e Android</b> pensate per essere navigate in modo semplice e intuitivo, sempre con un occhio di riguardo alla user experience.',    
        marketing: 'Digital Marketing',
        marketingText: 'Portiamo il tuo progetto in alto attraverso le migliori <b>campagne digital</b> e lo <b>studio</b> accurato <b>del target</b>, al fine di garantire ottimi risultati in termini di visibilità e sell-out.',
        customers: 'Alcuni clienti che hanno creduto in noi',
        environment: 'Tech, ma con un cuore.',
        environmentText: 'Crediamo che un’impresa debba contribuire fortemente alla collettività. Per questo ci impegniamo a dedicare tempo e risorse a progetti in campo sociale.',
        environment1: 'Piantiamo alberi',
        environment1Text: 'per compensare la C02 consumata',
        environment2: 'Supportiamo le comunità',
        environment2Text: 'per dare sostegno a chi ne ha più bisogno',
        environment3: 'Aiutiamo gli ultimi',
        environment3Text: 'per sostenerli nella vita di tutti i giorni',
        environment4: 'Alleviamo api',
        environment4Text: 'per aiutare il pianeta e la natura'
    },
    method: {
        assessment: 'Assessment',
        assessmentText: 'Analisi funzionale fondamentale alla base di ogni progetto.',
        design: 'Design',
        development: 'Develop',
        release: 'Release',
        title: 'Il nostro metodo',
        intro: 'intro',
        introText: 'Tutti dicono di avere un metodo. In pochi, però, possono affermare di averne uno proprio, ottimizzato ed efficace, frutto di anni di test sul campo. E tra quei pochi, ci siamo noi. Curiamo ogni progetto nel minimo dettaglio scandagliandolo in diverse fasi durante le quali manteniamo un dialogo costante con il cliente. Ascoltiamo le sue idee e lo guidiamo lungo la strada più indicata per le sue ambizioni.',
        index: 'indice',
    },
    manifesto: {
        title: 'Siamo tech e:',
        title1: 'giovani',
        text1: 'Young Endeavour Consulting. Più brevemente, YEC. Tre lettere che riassumono l’essenza e il DNA della nostra azienda, una realtà solida composta esclusivamente da giovani under 35 affamati di sfide e progetti intriganti. Dal 2016 a oggi di strada ne abbiamo fatta tanta e lungo il nostro percorso ci siamo imbattuti in tantissime persone che, in qualche modo, hanno tutte contribuito a dare forma a ciò che siamo oggi. Ci riferiamo ai clienti, con i quali abbiamo spesso stretto rapporti duraturi; ai colleghi, i quali hanno abbandonato il loro posto fisso per unirsi a noi alla ricerca di nuovi stimoli; ai fornitori, alleati preziosi per la realizzazione di importanti progetti.',
        title2: 'sensibili',
        text2: 'Come anticipato, a noi le sfide piacciono. E molto. Ma siamo consapevoli che al di fuori dei nostri uffici, al di là dei nostri computer, c’è chi, ogni giorno, si trova ad affrontare sfide molto più complesse delle nostre. Ci riferiamo ai senzatetto, alla ricerca costante di un riparo e un pezzo di pane per rimanere aggrappati alla vita; agli abitanti del terzo mondo, per i quali fuggire alla fame e alle malattie è pratica quotidiana da troppo tempo; al nostro pianeta, il quale è costretto a difendersi dai suoi stessi abitanti. Ed è proprio per questo che, tra un progetto e l’altro, noi di Gruppo YEC ci impegniamo costantemente per sostenere chi ha più bisogno di noi attraverso diversi progetti in ambito sociale e ambientale.',
        title3: 'affamati',
        text3: 'Siamo golosi di sfide, ne andiamo proprio ghiotti. Ci piace ascoltare le esigenze del cliente e, come fossero ingredienti, abbinarle al meglio al fine restituire una ricetta che faccia gola a tutti, anche a noi. Nei nostri progetti non mancano mai una buona dose di razionalità e pizzico di creatività, per rendere il tutto efficacie e allo stesso appetibile. Siamo golosi, ma anche un po’ ingordi. Le attività sviluppate con i nostri clienti non ci bastano e lo spazio nello stomaco per l’ultima fetta lo troviamo sempre. Per questo ci piace esagerare e impastare progetti personali, dallo sviluppo app agli e-commerce.',
        title4: 'elastici',
        text4: 'Alla “comfort zone” guardiamo con occhi annoiati, e più che una barriera protettiva ci pare un rigido e soffocante recinto. Ma per fortuna, di questo recinto, ne possediamo le chiavi e spesso e volentieri ce ne usciamo a scorrazzare tra ambienti e contesti che non conosciamo, per uscirne più arricchiti e ispirati che mai. Dalle mostre ai concerti, dalle grosse manifestazioni ai piccoli mercatini, dagli sport mai praticati prima ai film di un genere che non sopportiamo. E così via.',
        title5: 'liberi',
        text5: 'Il lavoro è importante, ma lo è altrettanto la vita privata. Per noi è un concetto imprescindibile. Molte realtà mettono questo aspetto in secondo piano, a vantaggio dei propri interessi. Gruppo YEC, invece, ha studiato un modello che permette di raggiungere risultati di business importanti senza dover “ingabbiare” il team tra le quattro mura dell’ufficio. Siamo fortemente convinti che la famiglia, lo sport e le passioni di ognuno contribuiscano in modo determinante alla serenità di un individuo, e che il tutto giovi di conseguenza alla sfera lavorativa. Il tutto attraverso un modello a misura d’uomo, tra smartworking e orari “umani”.',
        title6: 'designer',
        text6: 'Sviluppiamo progetti tecnologici altamente performanti. Ma anche belli da vedere e facili da usare. Perché da noi il monaco deve avere sempre un bell’abito. E pure molto comodo. In Gruppo YEC lo sviluppo va di pari passo con il design in modo da offrire allo user un’esperienza completa sotto ogni punto di vista, che si tratti di un app, un e-commerce, un sito web o una campagna adv. Ma la cosa che più ci fa apprezzare il nostro lavoro, e che ci permette di chiudere tutti i progetti al meglio, è l’approccio a “compartimenti comunicanti” che ci siamo auto-imposti, ossia dare la possibilità a ogni elemento del team di avere voce in capitolo anche in ambiti che esulano dal proprio compito all’interno dell’azienda.',
        title7: 'avanguardisti',
        text7: 'Se è vero che non bisogna mai dimenticare da dove si è partiti, è altrettanto vero che per crescere è necessario guardare avanti. Ed è quello che abbiamo sempre fatto. Siamo partiti sviluppando siti e-commerce e, a distanza di qualche anno, siamo diventati esperti consulenti nell’ambito blockchain e NFT Operiamo spalla a spalla coni grandi realtà e artisti, e disegniamo insieme a il futuro. Il loro e il nostro.',
        title8: 'organizzati',
        text8: 'Operiamo con metodo, anzi, con il nostro metodo. Negli anni abbiamo dato forma a una strategia cucita su misura alle nostre esigenze, e a quelle del cliente. Il primo approccio con il cliente è parte fondante del progetto in via sviluppo; attraverso un assessment con il cliente gettiamo le basi a quattro mani per un lavoro efficace e ben definito. Solo in seguito passiamo all’azione, attivando tutti gli attori necessari per l’avanzamento del progetto, fino al raggiungimento del risultato desiderato.',
        title9: 'onnipresenti',
        text9: 'Crediamo che il cliente non si debba mai sentire disorientato durante la realizzazione del proprio progetto. Siamo sviluppatori, designer ma anche attente guide pronte a indirizzare l’assistito durante ogni fase. Nello specifico, ci piace avviare un percorso di crescita insieme all’azienda o brand ponendoci come partner strategici attraverso consulenze proporzionate alle loro necessità. Non lasciamo nulla al caso: tutto viene studiato ed eseguito in modo minuzioso al fine di garantire serenità e soddisfazione al cliente.',
        
    },
    people: {
        title1: 'Sei anni d\'esperienza',
        title2: 'Tre punti fermi',
        text1: 'Dal 2017 a oggi abbiamo consolidato un metodo di lavoro che ci consente di sviluppare ogni progetto al meglio, in tempi brevi, senza lasciare nulla al caso: dall’aspetto creativo a quello tecnologico.',
        text2: 'Il nostro team è:',
        list1: 'Professionale, rapido e concreto',
        list2: 'Talentuoso, affamato e brillante',
        list3: 'Tecnologico e creativo',
        title3: 'Le nostre persone fanno di noi ciò che siamo',
        text3: 'Siamo orgogliosi di mettere al centro della nostra azienda le persone. Solo con lo scambio di idee, il confronto, lo stimolo reciproco è possibile garantire a Gruppo Yec e ai suoi Clienti, una continua crescita.',

    },
    careers: {
        title1: 'Vuoi fare parte del nostro team?',
        text1: 'Sali a bordo e cresci più di quanto tu possa immaginare.',
        explore: 'Esplora le carriere'
    },
    contact: {
        contact: 'Lasciaci un messaggio',
        subtitle: 'Siamo aperti a domande, idee, opportunità.<br/>Scrivici compilando il form sottostante. Ti risponderemo il prima possibile.',
        newsletter: 'Iscriviti alla nostra newsletter',
        name: 'Nome',
        surname: 'Cognome',
        email: 'Email',
        phone: 'Telefono',
        company: 'Azienda',
        message: 'Messaggio',
        privacy: 'Accetto i termini e condizioni e privacy policy',
        send: 'Invia messaggio',
        success: 'Messaggio inviato con successo. Ti risponderemo il prima possibile.',
        failure: 'Non è stato possibile inviare il messaggio. Controlla i campi e riprova.',
        privacyToAcept: 'Devi accettare la privacy policy per poter proseguire.',
        signin: 'Iscriviti'
    },
    services: {
        title1: 'Sviluppiamo prodotti innovativi e altamente performanti',
        text1: 'Siamo molto attenti alla progettazione dell\'infrastruttura software al fine di garantirne velocità di esecuzione e scalabilità nel tempo.',
        erp: 'Gestionali e industria 4.0',
        erpText: 'Il nostro ERP è pensato per favorire la trasformazione digitale delle aziende. Si compone di dieci soluzioni digitali da integrare in un sisitema sviluppato ad hoc per ogni azienda.',
        erpExplore: 'Visualizza le soluzioni',
        ecommerce: 'E-commerce',
        ecommerceText: 'Sviluppiamo e-commerce nativi e vantiamo partnership con i cms più diffusi sul mercato sia lato B2B che B2C. Integriamo gli e-commerce con i gestionali per agevolare la gestione dei flussi di sincronizzazione ordini, clienti e anagrafiche.',
        webapp: 'Piattaforme Web',
        webappText: 'Diamo vita al tuo progetto digitale partendo dall\'idea per arrivare al prodotto finito. E lo facciamo attraverso il linguaggio più adatto alle tue esigenze.',
        goToMethod: 'Il nostro metodo',
        blockchain: 'Blockchain',
        blockchainText: 'Supportiamo i clienti nell\'analisi e nell\'implementazione di soluzioni <b>Blockchain & DLT</b>. Offriamo a pubblici e privati i seguenti servizi: consulenza tecnologica, sviluppo blockchain, applicazioni distribuite (Dapps), sviluppo Smart Contracts.',
        app: 'Applicazioni',
        appText: 'Sviluppiamo applicazioni mobile per il mondo <b>iOS e Android</b> pensate per essere navigate in modo semplice e intuitivo, sempre con un occhio di riguardo alla user experience.',    
        marketing: 'Digital Marketing',
        marketingText: 'Portiamo il tuo progetto in alto attraverso le migliori <b>campagne digital</b> e lo <b>studio</b> accurato <b>del target</b>, al fine di garantire ottimi risultati in termini di visibilità e sell-out.',
        
    },
    showcase: {
        title1: 'Alcuni progetti',
    },
    callToAction: {
        title: 'Stai cercando un partner per il tuo prossimo progetto?',
        subtitle: 'Contattaci per maggiori informazioni.',
        button: 'Contattaci'
    }
};