import React, { useEffect } from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import logo from '../../assets/formafarma/logo.svg';
import formazione from '../../assets/formafarma/formazione.png';
import sales from '../../assets/formafarma/sales.png';
import marketing from '../../assets/formafarma/marketing.png';
import bg from '../../assets/formafarma/bg.jpg';
import img2 from '../../assets/formafarma/img_2.png';


import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { formaFarmaParticles } from '../../effects/particles';


export default function Formafarma() {

    useEffect(() => {

    }, []);

    const particlesInit = async (engine) => {
        console.log('loaded')
        await loadFull(engine);
    }


    return (
        <>
            <Container fluid style={{ marginTop: 70 }} className='p-0'>
                <div id='slide-bg-formafarma' style={{ backgroundImage: `url(${bg})` }}>
                    <Particles
                        options={formaFarmaParticles}
                        id="formafarma-particles"
                        init={particlesInit}
                    />
                    <Container fluid='md' id='slide-formafarma-content'>
                        <h1>Formazione.<br />Business.<br />FormaFarma</h1>
                    </Container>
                </div>
            </Container>


            <Container fluid>
                <Container fluid='md' className='mt-5 mb-5' id="formafarma">
                
                    <Image src={logo} height={30} className='mt-4 mb-4' />
                    <p className='big'>
                        Molto più di una piattaforma di e-learning. Formafarma è il nuovo <b>punto di incontro digitale</b> dedicato a <b>case farmaceutiche, aziende della cosmesi e farmacie</b>, dove sarà possibile formare al meglio i farmacisti e incrementare il proprio business.
                    </p>
                    

                    <Row className='align-items-center mt-5 mb-5'>
                        <Col xs={12} lg={4}>
                            <h2 style={{ color: '#01539D' }}>La ricetta per una piattaforma smart.</h2>
                            <p>
                                Intuitiva, efficace e ricca di features. Formafarma offre ad aziende e farmacie tutti gli <b>strumenti necessari per efficientare il proprio lavoro</b>, dai più classici ai più disruptive. Il tutto, attraverso un’esperienza di <b>navigazione fluida e piacevole</b>.
                            </p>
                        </Col>
                        <Col xs={12} lg={8}>
                            <Image src={img2} width='100%' />
                        </Col>
                    </Row>

                    <div className='mt-5 mb-5'>
                        <center>
                            <h2 style={{ color: '#01539D' }}>Una piattaforma tre plus aziendali</h2>
                        </center>

                        <Row className='mt-5'>
                            <Col xs={12} lg={4} className='pe-4'>
                                <Image src={formazione} width={'60'} height={'60'} className='mb-3' />
                                <h5>+ Formazione</h5>
                                <p>Formare ogni farmacista al meglio attraverso logiche di gamification e misurare il livello di formazione. Il tutto attraverso una piattaforma unica.</p>
                            </Col>
                            <Col xs={12} lg={4} className='pe-4'>
                                <Image src={sales} width={'60'} height={'60'} className='mb-3' />
                                <h5>+ Sales</h5>
                                <p>Aumentare le vendite, generare new business e acquisire nuove farmacie attraverso una rete in continua espansione.</p>
                            </Col>
                            <Col xs={12} lg={4} className='pe-4'>
                                <Image src={marketing} width={'60'} height={'60'} className='mb-3' />
                                <h5>+ Marketing</h5>
                                <p>Sfruttare una vetrina digitale per aumentare la visibilità dei prodotti, anche attraverso sponsorizzazioni. Lanciare ricerche di mercato e sondaggi in real-time.</p>
                            </Col>
                        </Row>
                    </div>

                </Container>
            </Container>
        </>
    );
};