import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Alert, Form, Row, Col } from 'react-bootstrap';
import { language } from '../core/language';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterForm from '../components/NewsletterForm';


export default function Contact() {

    const navigate = useNavigate();
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [company, setCompany] = useState('')
    const [privacyMessage, setPivacyMessage] = useState(false)
    const [messageStatus, setMessageStatus] = useState(null)
    const [MAILCHIMP_URL] = useState("https://gruppoyec.us14.list-manage.com/subscribe/post?u=ad8a77809b8372b6c8251b4ac&amp;id=90f0ab6ad6&amp;f_id=009ee7e5f0")

    useEffect(() => {

    }, []);


    function sendMessage() {
        if (privacyMessage === true) {
            var url = "https://backoffice.gruppoyec.com/contact/invia-email.php";
            let body = {
                name, surname, phone, email, message, company
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(body),
            }).then((response) => response.json()).then(async (responseData) => {
                console.log(responseData);
                setMessageStatus(responseData.status)
                if(responseData.status) {
                    navigate('/thanks')
                }
            }).catch((error) => {
                console.error(error);
            });
        }
        else {
            setMessageStatus('100')
        }
    }


    return (
        <Container fluid style={{ marginTop: 70 }} className='dark-slide'>
            <Container fluid='md'>
                <Row className='contact-table text-white pt-5 pb-5 ms-auto me-auto w-100'>
                    <Col xs={12} md={8}>
                        <h2 className='text-white'>{language.contact.contact}</h2>
                        <p dangerouslySetInnerHTML={{ __html: language.contact.subtitle }}></p>
                        <Row>
                            <Col xs={12} md={6} className='mt-3'>
                                <input className='mb-3' type='text' placeholder={language.contact.name} onChange={(event) => setName(event.target.value)} value={name} />
                                
                                <input className='mb-3' type='text' placeholder={language.contact.surname} onChange={(event) => setSurname(event.target.value)} value={surname} />
                                
                                <input className='mb-3' type='text' placeholder={language.contact.email} onChange={(event) => setEmail(event.target.value)} value={email} />
                                
                                <input className='mb-3' type='text' placeholder={language.contact.phone} onChange={(event) => setPhone(event.target.value)} value={phone} />
                                
                                <input type='text' placeholder={language.contact.company} onChange={(event) => setCompany(event.target.value)} value={company} />
                            </Col>
                            <Col xs={12} md={6} className='mt-3'>
                                <textarea placeholder={language.contact.message} onChange={(event) => setMessage(event.target.value)} value={message}></textarea>
                            </Col>
                        </Row>
                        <Form.Check
                            className='mt-3 mb-3'
                            type='checkbox'
                            onChange={() => setPivacyMessage(!privacyMessage)}
                            defaultChecked={privacyMessage}
                            label={language.contact.privacy}
                        />
                        <Button className='w-100' size='lg' variant='light' onClick={sendMessage}>{language.contact.send}</Button>

                        {(() => {
                            switch (messageStatus) {
                                case '200': return (
                                    <Alert variant='success' className='mt-3'>
                                        {language.contact.success}
                                    </Alert>
                                );
                                case '400': return (
                                    <Alert variant='danger' className='mt-3'>
                                        {language.contact.failure}
                                    </Alert>
                                );
                                case '100': return (
                                    <Alert variant='danger' className='mt-3'>
                                        {language.contact.privacyToAcept}
                                    </Alert>
                                );
                                default: return (
                                    <></>
                                );
                            }
                        })()}
                        <br />
                        <br />
                    </Col>
                    <Col xs={12} md={4}>
                        <h3 className='text-white'>{language.contact.newsletter}</h3>
                        <MailchimpSubscribe
                            url={MAILCHIMP_URL}
                            render={(props) => {
                                const { subscribe, status, message } = props || {};
                                return (
                                    <NewsletterForm
                                        status={status}
                                        message={message}
                                        onValidated={formData => subscribe(formData)}
                                    />
                                );
                            }}
                        />
                        <br />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};