import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Modal, Button } from 'react-bootstrap';
import CallToAction from '../../components/CallToAction';
import prestashop from '../../assets/servizi/ecommerce/prestashop.svg';
import shopify from '../../assets/servizi/ecommerce/shopify.svg';
import magento from '../../assets/servizi/ecommerce/magento.svg';
import adobe from '../../assets/servizi/ecommerce/adobe.svg';



export default function EcommerceB2c() {

    useEffect(() => {

    }, []);


    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    function handleCloseModal() {
        setShowModal(false);
        setModalContent(null)
    }
    function handleShowModal(content) {
        setModalContent(content)
        setShowModal(true);
    }


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h1>Conosciamo le migliori soluzioni tecnologiche per realizzare il <span style={{ color: '#418BFF' }}>sito e-commerce B2C</span> adatto alle tue necessità.</h1>
                    <Row className='mt-5 me-auto ms-auto' style={{maxWidth:700, filter:'grayscale(1)'}}>
                        <Col xs={6} md={3} className='p-4'>
                            <Image onClick={() => handleShowModal('Magento')} src={magento} height={60} />
                            <br/>
                            <span className='small text-secondary'>Magento</span>
                        </Col>
                        <Col xs={6} md={3} className='p-4'>
                            <Image onClick={() => handleShowModal('Shopify')} src={shopify} height={60} />
                            <br/>
                            <span className='small text-secondary'>Shopify Plus</span>
                        </Col>
                        <Col xs={6} md={3} className='p-4'>
                            <Image onClick={() => handleShowModal('Adobe')} src={adobe} height={60} />
                            <br/>
                            <span className='small text-secondary'>Adobe Commerce</span>
                        </Col>
                        <Col xs={6} md={3} className='p-4'>
                            <Image onClick={() => handleShowModal('Prestashop')} src={prestashop} height={60} />
                            <br/>
                            <span className='small text-secondary'>Prestashop</span>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid style={{ backgroundColor: '#1A2446' }}>
                <Container fluid='md' className='mt-5 pt-4 pb-3'>
                    <Row className='mt-5'>
                        <Col xs={12} lg={4} className='pe-5 pb-4 text-white'>
                            <h2>Un metodo firmato<br/><span style={{ color: '#418BFF' }}>GRUPPO YEC</span></h2>
                        </Col>
                        <Col xs={12} lg={8} className='pe-5 pb-4 text-white'>
                            <Row>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Assessment <sup className='ms-1 small text-secondary'><span className='small'>01</span></sup></h3>
                                    <p className='small'>È la fase cruciale di valutazione e analisi che precede l'implementazione o l'ottimizzazione di un negozio online. Durante questa fase, vengono esaminati e valutati tutti gli aspetti chiave del progetto, al fine di comprendere appieno le esigenze, le opportunità e le sfide.</p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Wireframe <sup className='ms-1 small text-secondary'><span className='small'>02</span></sup></h3>
                                    <p className='small'>Il wireframe è un tipo di rappresentazione visuale rudimentale, sotto forma di layout schematico, che viene utilizzato per delineare la struttura e la disposizione generale degli ingombri e delle pagine web del negozio online.</p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Mockup <sup className='ms-1 small text-secondary'><span className='small'>03</span></sup></h3>
                                    <p className='small'>Il mockup è una rappresentazione visuale dettagliata dell'aspetto previsto dell’e-commerce. A differenza del wireframe, il mockup è più avanzato e mira a mostrare come apparirà il design finale in termini di layout, colori, tipografia, immagini e stile.</p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Sviluppo IT <sup className='ms-1 small text-secondary'><span className='small'>04</span></sup></h3>
                                    <p className='small'>Lo sviluppo IT in un progetto di e-commerce rappresenta l'insieme delle attività dedicate alla creazione, all'implementazione e alla gestione della componente tecnologica necessaria per far funzionare il negozio online e per tradurre in codice il mockup precedentemente validato.</p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Testing <sup className='ms-1 small text-secondary'><span className='small'>05</span></sup></h3>
                                    <p className='small'>Il testing e il debugging in un progetto di e-commerce sono fasi cruciali che coinvolgono l'analisi e la verifica approfondita di tutte le componenti del negozio online al fine di garantire che funzionino correttamente e che soddisfino gli standard di qualità e sicurezza previsti.</p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Go Live <sup className='ms-1 small text-secondary'><span className='small'>06</span></sup></h3>
                                    <p className='small'>Questa fase rappresenta il momento in cui il negozio online viene ufficialmente lanciato e reso accessibile al pubblico. Dopo il Go-live, il lavoro continua con l'ottimizzazione, il marketing e la gestione dell'attività e-commerce per garantire il successo a lungo termine del progetto.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid>
                <Container fluid='md' className='mt-5 pt-2 pb-3'>
                    <Row className='mt-5'>
                        <Col xs={12} lg={4} className='pe-5 pb-4'>
                            <h2><span style={{ color: '#418BFF' }}>Ti garantiamo supporto</span> in tutte le seguenti fasi</h2>
                        </Col>
                        <Col xs={12} lg={8} className='pe-5 pb-4'>
                            <h3>Consulenza IT</h3>
                            <p>Per esempio, scegliere la piattaforma adatta alle tue specifiche esigenze è di fondamentale importanza per la buona riuscita del tuo progetto di vendita online. Banalmente la scelta della piattaforma da utilizzare influirà poi sulla scalabilità del portale e-commerce, sulle funzionalità, sulle Integrazioni e sulla flessibilità della piattaforma, sui costi e su tanti altri aspetti rilevanti. Altri aspetti chiave che non possono essere trascurati sono la logistica e gli aspetti legali e fiscali. Sviluppando piattaforme e-commerce per brand sia nazionali che internazionali, siamo costantemente aggiornati circa le nuove direttive fiscali, i regimi IVA, le policy GDPR e tutti gli aspetti più delicati di fisco e tassazione.</p>
                            
                            <h3 className='mt-5'>Design</h3>
                            <p>Durante una sessione di acquisto online, gli utenti preferiscono percorsi chiari e semplici che in pochi step li portino dalla scelta del prodotto all’acquisto, in totale sicurezza.
                            <br/>In sintesi, la UI/UX si concentra sull'aspetto e sull'esperienza di navigazione; la strategia creativa definisce come promuovere i tuoi prodotti; la produzione di contenuti crea il materiale per la promozione e la produzione di foto e video fornisce contenuti visivi di alta qualità per il tuo e-commerce. Tutti questi elementi collaborano per creare un'esperienza coinvolgente e persuasiva per i tuoi clienti.</p>

                            <h3 className='mt-5'>Strategia</h3>
                            <p>È il piano strategico che definisce come un'azienda intende utilizzare il canale di vendita online per raggiungere i propri obiettivi commerciali. La strategia è progettata per guidare tutte le attività correlate all'e-commerce, tra cui la definizione dei budget, il marketing online, il servizio clienti, l’analisi dei dati, gli aggiornamenti, e molto altro.</p>

                            <h3 className='mt-5'>CRM & Automation</h3>
                            <p>La marketing automation in un e-commerce è la strategia e l’insieme degli strumenti tecnologici progettati per automatizzare e ottimizzare le attività di marketing e comunicazione con i clienti. Questa pratica consente di creare e offrire esperienze personalizzate per i clienti, migliorare l'efficacia del marketing e aumentare le vendite, il tutto in modo efficiente. Un esempio di quelle che possono essere le automazioni circa i messaggi sono: l’invio automatico di email di conferma dell'ordine e di spedizione; l’invio di offerte personalizzate o codici sconto in base al comportamento del cliente; l’invio di messaggi di follow-up dopo un acquisto e il recupero di carrelli abbandonati attraverso email o notifiche push.</p>

                            <h3 className='mt-5'>Gestione della piattaforma</h3>
                            <p>Si riferisce alla gestione complessiva del negozio online, che include una serie di attività e responsabilità volte a garantire il corretto funzionamento, la crescita e il successo dell'attività di vendita online. Questo ruolo chiave è essenziale per assicurare che il negozio online funzioni senza intoppi, soddisfi le esigenze dei clienti e raggiunga gli obiettivi commerciali stabiliti. Rientrano la gestione e l’aggiornamento costante dell’inventario, la gestione delle promozioni e degli ordini, il customer care, ecc.</p>

                            <h3 className='mt-5'>Business Intelligence</h3>
                            <p>Si riferisce alla raccolta, all'analisi e all'utilizzo dei dati per prendere decisioni informate e ottimizzare le operazioni e le strategie commerciali relative all’e-commerce. La BI (business intelligence) è un elemento essenziale per il successo dell'e-commerce, poiché aiuta a comprendere meglio il comportamento dei clienti, a individuare opportunità di crescita e a migliorare l'efficacia della piattaforma IT e delle strategie.</p>

                            <h3 className='mt-5'>Marketing: SEO & SEM</h3>
                            <p>Le attività di marketing per l'e-commerce sono un insieme di strategie, tattiche e azioni finalizzate a promuovere il negozio online, attrarre traffico qualificato, coinvolgere i clienti e generare le vendite. Queste attività sono essenziali per la crescita e il successo di un e-commerce, poiché aiutano a creare consapevolezza del marchio, a generare interesse nei prodotti o servizi offerti e a convertire i visitatori in clienti. Supportiamo i nostri clienti nelle attività di SEO (migliorare il posizionamento sui motori di ricerca) e SEM (generare traffico di qualità mediante attività di promozione online). </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='p-0 mt-5'>
                <Row className='p-0 m-0'>
                    <Col xs={12} lg={6} style={{ backgroundColor: '#418BFF' }} className='text-white p-5'>
                        <Row className='align-items-center'>
                            <Col xs={12} lg={6} className='text-center text-md-start'>
                                <h4>E-commerce B2C</h4>
                            </Col>
                            <Col xs={12} lg={6} className='text-center'>
                                <Link to="/service/ecommerce-b2c">
                                    <span className='button light mt-4'>Scopri di più</span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6} style={{ backgroundColor: '#1A2446' }} className='text-white p-5'>
                        <Row className='align-items-center'>
                            <Col xs={12} lg={6} className='text-center text-md-start'>
                                <h4>E-commerce B2B</h4>
                            </Col>
                            <Col xs={12} lg={6} className='text-center'>
                                <Link to="/service/ecommerce-b2b">
                                    <span className='button light mt-4'>Scopri di più</span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>


            <CallToAction />


            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered scrollable>
                <Modal.Body>
                    <Container className='p-4'>
                        {modalContent && (
                            <>
                                {modalContent === 'Magento' && (
                                    <>
                                        <Image src={magento} height={60} className='mb-4' />
                                        <h3 className='mb-4'>Magento 2 è la regina delle piattaforme e-commerce, adottata dalle medie e grandi imprese.</h3>
                                        <p>Attualmente Magento 2 si è evoluto nelle seguenti tre versioni:</p>
                                        <ul>
                                            <li className='mb-4'>
                                                Magento Open SourceIn passato identificata come Magento 2 Community Edition, la versione gratuita e open source della
                                                piattaforma Magento 2 offre un accesso completo al suo codice, consentendo personalizzazioni illimitate e la possibilità di
                                                ospitare l'e-commerce su qualsiasi piattaforma. Attualmente la più diffusa, questa edizione riceve supporto da parte di Adobe,
                                                garantendo aggiornamenti di sicurezza, correzioni di bug e miglioramenti continui.
                                            </li>
                                            <li className='mb-4'>
                                                Adobe CommerceIn passato conosciuta come Magento Commerce e Magento 2 Enterprise Edition, Adobe Commerce
                                                rappresenta la versione enterprise della rinomata piattaforma Magento 2 ed è la principale soluzione di e-commerce di Adobe.
                                                Caratterizzato da una robustezza straordinaria, una scalabilità elevata e un ricco set di funzionalità avanzate, Adobe Commerce è
                                                disponibile in modalità SaaS e richiede una licenza basata sul fatturato online.
                                            </li>
                                            <li className='mb-4'>
                                                Adobe Commerce Cloud è la versione Adobe Commerce ma ospitata sull’infrastruttura di Adobe.
                                            </li>
                                        </ul>
                                        <p>
                                            Range di costo per lo sviluppo di un e-Commerce B2C su Magento2:<br/>
                                            € 35.000 - € 50.000.
                                        </p>
                                    </>
                                )}

                                {modalContent === 'Adobe' && (
                                    <>
                                        <Image src={adobe} height={60} className='mb-4' />
                                        <h3 className='mb-4'>Adobe Commerce è l’evoluzione di Magento targata Adobe.</h3>
                                        <p>
                                            Adobe Commerce è un software di e-commerce omnicanale agile, flessibile e completamente estendibile. Grazie a un’architettura
                                            headless basata su cloud e a strumenti specializzati come Adobe Developer App Builder, è facile scalare e integrare le esperienze
                                            di e-commerce su tutti i punti di contatto digitali.
                                        </p>
                                        <p>
                                            Con Adobe Commerce, hai la flessibilità di scegliere tra un modello SaaS (Software as a Service) o On-premise, con licenze
                                            determinate in base al tuo fatturato online, permettendoti di selezionare l’opzione che meglio si adatta ai tuoi obiettivi di business.
                                        </p>
                                        <p>
                                            Range di costo per lo sviluppo di un e-Commerce B2C su Adobe Commerce:<br/>
                                            € 45.000 - € 65.000.
                                        </p>
                                    </>
                                )}

                                {modalContent === 'Shopify' && (
                                    <>
                                        <Image src={shopify} height={60} className='mb-4' />
                                        <h3 className='mb-4'>Shopify Plus è tra le piattaforme e-Commerce più utilizzate al mondo.</h3>
                                        <p>
                                            Shopify Plus è una piattaforma commerciale all’avanguardia progettata per aiutare le aziende a crescere rapidamente e in modo
                                            efficiente. Offre una gamma completa di funzionalità che vanno dalla vendita su vari canali e mercati, all’ottimizzazione delle
                                            conversioni, fino alla scalabilità e all’innovazione rapida. Con Shopify Plus, puoi vendere online, di persona, all’ingrosso e sui social
                                            media, tutto da una piattaforma unificata che offre dati e report centralizzati.
                                        </p>
                                        <p>
                                            Range di costo per lo sviluppo di un e-Commerce B2C su ShopifyPlus:<br/>
                                            € 25.000 - € 45.000.
                                        </p>
                                    </>
                                )}

                                {modalContent === 'Prestashop' && (
                                    <>
                                        <Image src={prestashop} height={60} className='mb-4' />
                                        <h3 className='mb-4'>Prestashop è una piattaforma di sviluppo di e-commerce open source.</h3>
                                        <p>
                                            È principalmente utilizzata per la creazione di siti e-Commerce per start-up e piccole realtà aziendali.
                                        </p>
                                        <p>
                                            Range di costo per lo sviluppo di un e-Commerce B2C su Prestashop:<br/>
                                            € 12.000 - € 15.000.
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        
                    </Container>
                </Modal.Body>
                <Modal.Footer className='text-center'>
                    <Button variant='outline-secondary' onClick={handleCloseModal}>Chiudi</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};