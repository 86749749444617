import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Image, Row, Col } from 'react-bootstrap';
import assessment from '../assets/metodo/Assessment.svg';
import design from '../assets/metodo/Design.svg';
import development from '../assets/metodo/Development.svg';
import release from '../assets/metodo/Release.svg';
import { language } from '../core/language';
import CallToAction from '../components/CallToAction';


export default function Method() {

    useEffect(() => {

    }, []);


    function scrollToDiv(id) {
        const section = document.querySelector('#' + id);
        if (section !== null)
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>

                <Container fluid='md' className='pt-5 pb-5'>
                    <h1>{language.method.title}</h1>
                    <Row>
                        <Col xs={12} lg={8} className='pe-5'>
                            <h5 className='flex'><span className='title'>{language.method.intro}</span> <span className='line'></span></h5>
                            <p>{language.method.introText}</p>
                        </Col>
                        <Col xs={12} lg={4} className='pe-5'>
                            <h5 className='flex'><span className='title'>{language.method.index}</span> <span className='line'></span></h5>
                            <ul className='numbered'>
                                <li onClick={() => scrollToDiv('assessment')}><span className='number'>01</span>{language.method.assessment}</li>
                                <li onClick={() => scrollToDiv('design')}><span className='number'>02</span>{language.method.design}</li>
                                <li onClick={() => scrollToDiv('development')}><span className='number'>03</span>{language.method.development}</li>
                                <li onClick={() => scrollToDiv('release')}><span className='number'>04</span>{language.method.release}</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>


                <Container fluid='md' className='mb-4'>
                    <div id="assessment" className='container-sticky'>
                        <div className='sticky-col'>
                            <div>
                                <h2>{language.method.assessment}</h2>
                                <Image alt={language.method.assessment} width={'70'} height={'70'} src={assessment} />
                            </div>
                        </div>
                        <div className='scroll-col'>
                            <br />
                            <p>Il tutto ha inizio da un assessment, ossia il risultato di una serie di incontri tra cliente e Gruppo YEC utili per redigere un documento completo di tutte le informazioni necessarie per avviare il progetto. <br />Si parte con un&rsquo;<strong>Analisi Funzionale</strong> attraverso la quale si osservano da vicino gli strumenti attualmente in uso, si identificano eventuali database esistenti per ricavare dati utili al progetto e si studia l&rsquo;architettura hardware di sistema a disposizione del cliente.<br />Successivamente si passa all&rsquo;<strong>Analisi Preliminare del Nuovo Progetto. </strong>In questa fase si identificano gli obiettivi a breve e medio-lungo termine, si definisce una nuova strategia di digitalizzazione e si identificano e analizzano i reparti interni coinvolti. Inoltre vengono individuati e intervistati gli stakeholder (stakeholder mapping e stakeholder interview). <br />Terminata l&rsquo;analisi preliminare, &egrave; il momento della <strong>Definizione del Progetto Esecutivo</strong>. Durante questa fase si definiscono e si mappano tutti i processi del progetto che, a loro volta, vengono analizzati uno a uno attraverso l&rsquo;identificazione del flusso di dati. Infine si identificano e analizzano le entit&agrave; e le relazioni necessarie a modellizzare il flusso operativo, e si fa confluire il tutto in un <strong>GANTT</strong> di riferimento per la realizzazione del nuovo software.</p>
                        </div>
                    </div>
                </Container>


                <Container fluid='md' className='mb-4'>
                    <div id="design" className='container-sticky'>
                        <div className='sticky-col'>
                            <div>
                                <h2>{language.method.design}</h2>
                                <Image alt={language.method.design} width={'70'} height={'70'} src={design} />
                            </div>
                        </div>
                        <div className='scroll-col'>
                            <h2>Wire Frame</h2>
                            <p>Una volta effettuato l&rsquo;Assessment, si comincia a dare forma visiva al progetto. In questa fase, attraverso il supporto del nostro team creativo e strategico, si studiano gli ingombri e la disposizione degli elementi necessari per la navigazione del software. Insieme al cliente si valuta l&rsquo;impostazione e si effettuano eventuali migliorie o aggiustamenti.</p>
                            <h2>Mockup</h2>
                            <p>Definito il Wire Frame, si comincia a popolare il software attraverso lo sviluppo di un Mockup, pagina dopo pagina, sezione dopo sezione. Testi, colori, immagini fotografiche, illustrazioni e quant&rsquo;altro vengono inseriti nelle rispettive aree per permettere al cliente di avere una preview di come potrebbe apparire il proprio progetto, una volta ultimato. Come avviene durante la fase di Wire Frame, anche durante questa fase sono previsti aggiustamenti sulla base delle richieste del cliente, purch&eacute; non stravolgano il Wire Frame.</p>
                            <h2>Usability Test</h2>
                            <p>Terminato lo sviluppo del Mockup, si passa a una fase fondamentale definita &ldquo;Usability Testing&rdquo;, ossia lo studio del comportamento di alcuni utenti durante lo svolgimento di determinati task durante la navigazione. Questo approccio &egrave; fondamentale per fornire al designer gli input necessari per ovviare a eventuali difficolt&agrave; che possono rendere difficoltosa la user experience.</p>
                        </div>
                    </div>
                </Container>


                <Container fluid='md' className='mb-4'>
                    <div id="development" className='container-sticky'>
                        <div className='sticky-col'>
                            <div>
                                <h2>{language.method.development}</h2>
                                <Image alt={language.method.development} width={'70'} height={'70'} src={development} />
                            </div>
                        </div>
                        <div className='scroll-col'>
                            <h2>Coding</h2>
                            <p>Definito il Mockup e superata la fase dell&rsquo;Usability Testing, la palla passa agli sviluppatori i quali attraverso il coding trasformano una semplice simulazione in un software, sito o app veri e propri. Il prodotto si avvicina cos&igrave; sempre pi&ugrave; alla versione definitiva.&nbsp;</p>
                            <h2>Testing</h2>
                            <p>In parallelo, durante determinati step del Coding &egrave; fondamentale effettuare il Testing, al fine di individuare la correttezza e l&rsquo;affidabilit&agrave; del software in corso di sviluppo. Nello specifico consiste nel testare il progetto in autonomia o in combinazione con un software di servizio, e nel valutare se le risposte rispettino o meno i requisiti.</p>
                            <h2>Debugging</h2>
                            <p>Una volta definiti eventuali errori si passa al Debugging, ossia la correzione delle eventuali criticit&agrave;. Questa attivit&agrave;, nel corso del progetto, risulta tra le pi&ugrave; importanti ma anche tra le pi&ugrave; ostiche a seconda della complessit&agrave; del progetto. Il rischio, infatti, &egrave; quello di introdurre nuovi errori nel tentativo di correggerne altri. Per questo motivo il nostro team &egrave; formato esclusivamente da personale estremamente esperto e preparato.</p>
                        </div>
                    </div>
                </Container>


                <Container fluid='md' className='mb-5'>
                    <div id="release" className='container-sticky'>
                        <div className='sticky-col'>
                            <div>
                                <h2>{language.method.release}</h2>
                                <Image alt={language.method.release} width={'70'} height={'70'} src={release} />
                            </div>
                        </div>
                        <div className='scroll-col'>
                            <h2>Production</h2>
                            <p>Portate a&nbsp;termine tutte le fasi fin qui descritte, si pu&ograve; definitivamente ritenere concluso il progetto e procedere cos&igrave; al lancio ufficiale. Nel caso questo necessiti di aggiornamenti nel corso del tempo, il team di Gruppo YEC &egrave; presente e preparato per intervenire al meglio.</p>
                            <h2>Marketing</h2>
                            <p>Qualora il progetto, una volta sviluppato, necessiti di una &ldquo;spinta&rdquo; per raggiungere o superare gli obiettivi di business prefissati, il team creativo-strategico di Gruppo YEC c&rsquo;&egrave;. Dall&rsquo;ADV on-line alle campagne social, dalla stampa alle brochure: sviluppiamo ogni tipologia di comunicazione marketing per raccontare al meglio la tua attivit&agrave;.</p>
                        </div>
                    </div>
                </Container>

            </Container>

            <CallToAction/>

        </>
    );
};