import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import bg from '../assets/careers/bg-gradient.jpeg';
import rocket from '../assets/careers/rocket.png';
import heart from '../assets/careers/heart.png';
import hands from '../assets/careers/hands.png';
import pencil from '../assets/careers/pencil.png';


export default function Careers() {

    useEffect(() => {

    }, []);


    return (
        <Container fluid='md' style={{ marginTop: 70 }}>

            <Container className='pt-5 pb-5'>
                <h1>Careers at gruppoyec</h1>

                <Row className='align-items-stretch'>
                    <Col xs={12} md={8} className='mb-3'>
                        <Card style={{height:'100%'}}>
                            <Card.Body style={{maxWidth:400}}>
                                <h2>Viviamo per salire di livello</h2>
                                <p>Cerchiamo attivamente opportunità per favorire la nostra crescita e il nostro sviluppo.</p>
                            </Card.Body>
                            <Card.Footer className='bg-transparent border-0'>
                                <Image src={rocket} height={64} className='m-4' />
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} className='mb-3'>
                        <Card className='border-0' style={{height:'100%', backgroundImage:`url(${bg})`, backgroundSize:'cover'}}>
                            <Card.Body style={{maxWidth:300}}>
                                <h2>Esprimiamo amorevolmente la nostra opinione</h2>
                                <p>Comunichiamo in modo chiaro e aperto, garantendo che tutti siano preparati per avere successo.</p>
                            </Card.Body>
                            <Card.Footer className='bg-transparent border-0'>
                                <Image src={heart} height={64} className='m-4' />
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} className='mb-3'>
                        <Card className='border-0' style={{height:'100%', backgroundImage:`url(${bg})`, backgroundSize:'cover'}}>
                            <Card.Body style={{maxWidth:300}}>
                                <h2>Testiamo e iteriamo rapidamente</h2>
                                <p>Sperimentiamo e ci adattiamo per ottenere risultati, testando soluzioni rapidamente.</p>
                            </Card.Body>
                            <Card.Footer className='bg-transparent border-0'>
                                <Image src={pencil} height={64} className='m-4' />
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={12} md={8} className='mb-3'>
                        <Card style={{height:'100%'}}>
                            <Card.Body style={{maxWidth:400}}>
                                <h2>Ci assumiamo la responsabilità</h2>
                                <p>Ci assumiamo la responsabilità del nostro lavoro e delle nostre azioni, facendo la nostra parte per raggiungere un obiettivo di gruppo.</p>
                            </Card.Body>
                            <Card.Footer className='bg-transparent border-0'>
                                <Image src={hands} height={64} className='m-4' />
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
            <Container className='pt-5 pb-5'>
                <h2>Posizioni aperte</h2>

                <div className='mb-5'>
                    <p><b>Commerciale Junior</b><br/>
                    <span className='small'>Cerchiamo Commerciale <i>junior</i> per affiancamento.</span></p>
                </div>

                <div className='mb-5'>
                    <p><b>Web Developer</b><br/>
                    <span className='small'>Cerchiamo web developer con conoscenza approfondita di Javascript (React Js) e PHP.</span></p>
                </div>


                <div className='text-center'>
                    <Link to="/contact">
                        <span className='button'>
                            Contattaci
                        </span>
                    </Link>
                </div>
            </Container>

        </Container>
    );
};