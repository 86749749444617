import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import CallToAction from '../../components/CallToAction';



export default function WebAndApp() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h1>
                        Siamo una <span style={{ color: '#418BFF' }}>società full-custom</span>, ossia scriviamo codice da
                        zero per soddisfare tutte le specifiche esigenze dei nostri
                        clienti. Vuol dire non avere vincoli. Vuol dire garantire
                        prodotti performanti e di altissima qualità.
                    </h1>
                </Container>
            </Container>


            <Container fluid className='p-0 mt-5'>
                <Row className='p-0 m-0'>
                    <Col xs={12} lg={6} style={{ backgroundColor: '#418BFF' }} className='text-white p-5'>
                        <Row className='align-items-center'>
                            <Col xs={12} lg={6} className='text-center text-md-start'>
                                <h4>Piattaforme custom</h4>
                                <p>
                                    Diamo vita al tuo progetto digitale partendo dall’idea per arrivare al prodotto finito.
                                    E lo facciamo attraverso le tecnologie più adatte alle tue esigenze.
                                </p>
                            </Col>
                            <Col xs={12} lg={6} className='text-center'>
                                <Link to="/service/piattaforme-web">
                                    <span className='button light mt-4'>Scopri di più</span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6} style={{ backgroundColor: '#1A2446' }} className='text-white p-5'>
                        <Row className='align-items-center'>
                            <Col xs={12} lg={6} className='text-center text-md-start'>
                                <h4>Siti corporate</h4>
                                <p>
                                    Progettiamo e sviluppiamo siti aziendali occupandoci di tutte le fasi, dal design allo
                                    sviluppo IT. Lo facciamo attraverso i CMS più performanti oppure sviluppandoli custom.
                                </p>
                            </Col>
                            <Col xs={12} lg={6} className='text-center'>
                                <Link to="/service/siti-corporate">
                                    <span className='button light mt-4'>Scopri di più</span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>


            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h2>
                        Lavoriamo con la metodologia Agile Kanban, ossia utilizziamo un approccio di sviluppo 
                        del software che si basa su <span style={{ color: '#418BFF' }}>principi di flessibilità, adattabilità e collaborazione</span>, per gestire progetti complessi.
                    </h2>
                    <p className='lead'>
                        Questa metodologia agile si basa su un flusso continuo di lavoro senza sprint (cicli di sviluppo) e impone limiti al numero di attività
                        che possono essere gestite contemporaneamente. Questo, mira a ridurre l accumulo di lavoro e migliorare la concentrazione e le
                        performance. A differenza di altre metodologie agile, la Kanban non definisce ruoli specifici come lo Scrum Master o il Product
                        Owner, ma chiunque prende parte al progetto può gestire la tavola Kanban, ossia gli aggiornamenti. Infine, tale metodologia si basa 
                        sull osservazione continua del flusso di lavoro per identificare eventuali blocchi e possibili miglioramenti.
                    </p>
                </Container>
            </Container>


            <Container fluid style={{ backgroundColor: '#1A2446' }}>
                <Container fluid='md' className='mt-5 pt-4 pb-3'>
                    <Row className='mt-5'>
                        <Col xs={12} lg={4} className='pe-5 pb-4 text-white'>
                            <h2>Questa metodologia<br/><span style={{ color: '#418BFF' }}>come impatta sul cliente</span>?</h2>
                        </Col>
                        <Col xs={12} lg={8} className='pe-5 pb-4 text-white'>
                            <h3>Più comunicazione e collaborazione tra i membri del team.</h3>
                            <p>Il Cliente è certo di ricevere ciò che ha comunicato ai referenti del progetto.</p>

                            <h3 className='mt-5'>Più flessibilità e adattabilità.</h3>
                            <p>Il Cliente può modificare alcune richieste anche in fase di esecuzione.</p>

                            <h3 className='mt-5'>Più trasparenza.</h3>
                            <p>Il Cliente può visualizzare giornalmente la tavola Kanban/gantt avendo una visione chiara e istantanea del progresso del progetto.</p>

                            <h3 className='mt-5'>Consegna incrementale.</h3>
                            <p>Il Cliente può testare di volta in volta gli sviluppi incrementali che vengono rilasciati. Più feedback e maggiore certezza di consegnare un prodotto che viene realmente apprezzato.</p>

                            <h3 className='mt-5'>Maggiore soddisfazione del team.</h3>
                            <p>La gestione degli oneri di lavoro aiutano a mantenere un ambiente equilibrato e sereno. Un team più soddisfatto tende a fornire risultati di qualità superiore.</p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <CallToAction />

        </>
    );
};