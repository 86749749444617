import React, { useEffect } from 'react';


export default function Pixel() {

    useEffect(() => {

    }, []);

    return (
        <>
            
        </>
    );
};