import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import icoWeb from '../assets/static/web.svg';
import icoEcommerce from '../assets/static/e-commerce.svg';
import icoErp from '../assets/static/erp.svg';
import icoBlockchain from '../assets/static/blockchain.svg';
import icoMarketing from '../assets/static/marketing.svg';
import icoApp from '../assets/static/app.svg';
import comunita from '../assets/images/comunita.png';
import sociale from '../assets/images/sociale.png';
import foresta from '../assets/images/alberi.jpg';
import api from '../assets/images/api.jpg';
import bg from '../assets/images/copertina.jpg';
import bcorp from '../assets/bcorp.svg';
import Reviews from '../components/Reviews';


export default function Home() {

    const cache = {};

    function importAll(r) {
        r.keys().forEach((key) => (cache[key] = r(key)));
    }
    importAll(require.context("../assets/customers/", false, /\.(png|jpe?g|svg)$/));

    const logos = Object.entries(cache).map(module => module[1]);


    return (
        <>
            <Container fluid className='p-0' style={{backgroundColor:'#1A2446'}}>
                <div id='slide-bg-home' style={{ backgroundImage: `url(${bg})`, paddingTop: 70 }}>
                    <Container fluid="md" id='slide-home-content'>
                        <div style={{ maxWidth: 1000 }}>
                            <h1>La boutique del software per portare in alto il tuo business.</h1>
                            <p className='lead'>
                                Siamo professionisti del software su misura esperti nel creare soluzioni digitali che si adattano alle tue esigenze.
                                <br/><br/>
                                Crediamo fortemente nel potere della tecnologia per favorire la crescita aziendale, permettendo l'ottimizzazione dei processi, l'incremento della produttività e l'apertura di nuove opportunità.
                            </p>
                        </div>
                    </Container>
                </div>
            </Container>


            <Container fluid className='' style={{ overflow: 'hidden', backgroundColor: '#F4F7FF'}}>
                <Container fluid='md' style={{ maxWidth: 960 }}>
                    <Row className='mt-5 mb-5 align-items-center'>
                        <Col xs={12} md={3} className='pt-4 pb-4'>
                            <Image width={150} style={{ maxWidth: '80%' }} src={bcorp} className='mb-3' />
                        </Col>
                        <Col xs={12} md={9} className='pt-4 pb-4'>
                            <h3>Siamo una B Corp certificata!</h3>
                            <p>
                                Nel 2024, GRUPPO YEC è diventata una B Corporation certificata, consolidando ulteriormente l'impegno nella creazione di un impatto positivo sui propri dipendenti, sulla società e sull'ambiente. Le B Corp sono aziende che hanno raggiunto “i più alti standard di prestazioni sociali e ambientali verificate” e conciliano l'economia e il profitto con l'etica, la sostenibilità e il benessere. 
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Container fluid className='bg-white' style={{ overflow: 'hidden' }}>
                <Container fluid='md' style={{ marginTop: 70 }}>
                    <p className='text-muted'>I NOSTRI SERVIZI</p>
                    <h2>Di cosa ci occupiamo?</h2>

                    <Row className='mt-5'>
                        <Col xs={12} md={6} className='pe-5 pb-5'>
                            <Image height={'60'} src={icoWeb} className='mb-3' />
                            <h3>Piattaforme Web</h3>
                            <p>Dai vita al tuo <b>progetto digitale</b> con una soluzione cloud su misura. Supportiamo le aziende partendo dall'idea per arrivare al prodotto finito.</p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-5'>
                            <Image height={'60'} src={icoErp} className='mb-3' />
                            <h3>Gestionali</h3>
                            <p>Gestisci e monitora tutte le operazioni aziendali per <b>incrementare l’efficienza</b>. Analizza dati e report per avere <b>tutto sotto controllo</b>.</p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-5'>
                            <Image height={'60'} src={icoEcommerce} className='mb-3' />
                            <h3>E-commerce B2B e B2C</h3>
                            <p><b>Incrementa il fatturato</b> attivando un nuovo canale di vendita dedicato ai tuoi clienti privati. <b>Digitalizza il processo</b> di acquisto dei tuoi clienti business, garantendo organizzazione e tempestività.</p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-5'>
                            <Image height={'60'} src={icoApp} className='mb-3' />
                            <h3>Applicazioni</h3>
                            <p>Crea app avvincenti oppure utili strumenti per gestire la quotidianità. Accedi a milioni di utenti paganti con <b>app iOS e Android</b> di altissimo livello.</p>
                        </Col>
                       {/*
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoBlockchain} className='mb-3' />
                            <h3>Blockchain</h3>
                            <p>Supportiamo i clienti nell'analisi e nell'implementazione di soluzioni Blockchain & DLT. Offriamo a pubblici e privati i seguenti servizi: consulenza tecnologica, sviluppo blockchain, applicazioni distribuite (Dapps), sviluppo Smart Contracts.</p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoMarketing} className='mb-3' />
                            <h3>Digital Marketing</h3>
                            <p>Portiamo il tuo progetto in alto attraverso le migliori campagne digital e lo studio accurato del target, al fine di garantire ottimi risultati in termini di visibilità e sell-out.</p>
                        </Col>
                        */}
                    </Row>
                </Container>
            </Container>


            <Container fluid className='mt-5' style={{backgroundColor: '#F4F7FF'}}>
                <Container fluid='md' className='pt-5 pb-5 text-center text-dark'>
                    <h2>
                        Il nostro metodo
                    </h2>
                    <h3 style={{maxWidth: 900}} className='ms-auto me-auto'>
                        Sviluppiamo e gestiamo piattaforme cloud innovative, grazie all’utilizzo di tecnologie sempre all’avanguardia. <span style={{ color: '#418BFF' }}>Pianifichiamo e supportiamo tutte le fasi del processo</span>, dalla strategy allo sviluppo, dalla gestione al marketing, dagli aspetti legali e fiscali alla logistica.
                    </h3>
                    <Link to="/method">
                        <span className='button mt-4'>Scopri di più</span>
                    </Link>
                </Container>
            </Container>


            <Container fluid className='p-0 bg-white' style={{ overflow: 'hidden' }}>
               
                <Reviews />

                <Container fluid='md' className='mt-5 text-center'>
                    <h2>Alcuni clienti che hanno creduto in noi</h2>
                    <br />

                    <div className='customers-grid'>
                        {logos.map((logo, i) => {
                            return (
                                <div key={i} className='customer-logo'><Image src={logo} /></div>
                            )
                        })}
                    </div>
                </Container>


                <Container fluid className='mt-5' style={{backgroundColor: '#F4F7FF'}}>
                    <Container fluid='md' className='pt-5 pb-5 text-center text-white'>
                        <h3 style={{maxWidth: 700}} className='ms-auto me-auto text-dark'>
                            Gruppo YEC ha preso la decisione di investire il proprio tempo in <span style={{ color: '#418BFF' }}>iniziative benefiche</span>, con l'obiettivo di promuovere attivamente il contributo al benessere della comunità.
                        </h3>
                        <Link to="/sociale">
                            <span className='button mt-4'>Scopri di più</span>
                        </Link>
                    </Container>
                </Container>


                <Container fluid className='dark-slide'>
                    <div className='pt-5 pb-5' id='environment'>
                        <Container fluid='md' className='text-center text-white'>
                            <h1>Tech, ma con un cuore.</h1>
                            <p style={{maxWidth: 700}} className='ms-auto me-auto lead text-white'>Crediamo che un’impresa debba contribuire fortemente alla collettività. Per questo ci impegniamo a dedicare tempo e risorse a progetti in campo sociale.</p>
                        </Container>
                        
                        <Container fluid='md'>
                            <Row>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={'Piantiamo alberi'} src={foresta} width='100%' />
                                    <h3 className='text-white mb-0'>Piantiamo alberi</h3>
                                    <p className='text-secondary'>per compensare la C02 consumata</p>
                                </Col>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={'Supportiamo le comunità'} src={comunita} width='100%' />
                                    <h3 className='text-white mb-0'>Supportiamo le comunità</h3>
                                    <p className='text-secondary'>per dare sostegno a chi ne ha più bisogno</p>
                                </Col>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={'Aiutiamo gli ultimi'} src={sociale} width='100%' />
                                    <h3 className='text-white mb-0'>Aiutiamo gli ultimi</h3>
                                    <p className='text-secondary'>per sostenerli nella vita di tutti i giorni</p>
                                </Col>
                                <Col xs={6} lg={3} className='p-4'>
                                    <Image alt={'Alleviamo api'} src={api} width='100%' />
                                    <h3 className='text-white mb-0'>Alleviamo api</h3>
                                    <p className='text-secondary'>per aiutare il pianeta e la natura</p>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </Container>
            </Container>
        </>
    )

};