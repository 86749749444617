import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import CallToAction from '../../components/CallToAction';
import icoMarketing from '../../assets/static/marketing.svg';
import icoAdv from '../../assets/servizi/marketing/adv.svg';
import icoLead from '../../assets/servizi/marketing/lead.svg';
import banner from '../../assets/servizi/marketing/banner.jpg';
import facebook from '../../assets/servizi/marketing/facebook.svg';
import instagram from '../../assets/servizi/marketing/instagram.svg';
import youtube from '../../assets/servizi/marketing/youtube.svg';
import tiktok from '../../assets/servizi/marketing/tiktok.svg';
import linkedin from '../../assets/servizi/marketing/linkedin.svg';
import google from '../../assets/servizi/marketing/google.svg';



export default function Marketing() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>

                <Container fluid='md' className='pt-5'>
                    <Image height={'70'} src={icoMarketing} className='mb-3' />
                    <h1 style={{ color: '#0178c3' }}>Porta in alto il tuo progetto.</h1>
                    <p className='lead'>
                        Sia che si tratti di un e-commerce, di un’app oppure di un’altra tipologia di business, Gruppo YEC
                        studia le migliori campagne di performance marketing per aiutarti a raggiungere i tuoi obiettivi.
                        E, perché no, superarli.
                    </p>
                </Container>


                <Container fluid className='bg-secondary p-0 mt-5'>
                    <Image src={banner} width={'100%'} />
                </Container>


                <Container fluid='md' className='mt-5'>

                    <h2>Servizi</h2>

                    <Row className='mt-5'>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoAdv} className='mb-3' />
                            <h3>Social Advertising</h3>
                            <p>Aumenta le vendite e acquisisci nuovi clienti
                            attraverso campagne social targettizzate
                            e ottimizzate. Il team di Gruppo YEC cura
                            giornalmente i canali social di numerose attività
                            locali e PMI, portando a casa importanti risultati in
                            termini di conversione ed engagement.</p>
                        </Col>
                        <Col xs={12} md={6} className='pe-5 pb-4'>
                            <Image height={'30'} src={icoLead} className='mb-3' />
                            <h3>Lead generation</h3>
                            <p>Fai impennare il numero di clienti attraverso
                            campagne di lead generation. Gruppo YEC
                            supporta quotidianamente il tuo business
                            nell’acquisizione di nuovi clienti grazie a una
                            comunicazione mirata ed efficace.</p>
                        </Col>
                    </Row>

                    
                    <Row className='mt-5 mb-5'>
                        <Col xs={6} lg={2}>
                            <Image src={facebook} height={40} className='mb-2' />
                            <p>Facebook</p>
                        </Col>
                        <Col xs={6} lg={2}>
                            <Image src={instagram} height={40} className='mb-2' />
                            <p>Instagram</p>
                        </Col>
                        <Col xs={6} lg={2}>
                            <Image src={youtube} height={40} className='mb-2' />
                            <p>YouTube</p>
                        </Col>
                        <Col xs={6} lg={2}>
                            <Image src={tiktok} height={40} className='mb-2' />
                            <p>TikTok</p>
                        </Col>
                        <Col xs={6} lg={2}>
                            <Image src={linkedin} height={40} className='mb-2' />
                            <p>Linkedin</p>
                        </Col>
                        <Col xs={6} lg={2}>
                            <Image src={google} height={40} className='mb-2' />
                            <p>Google Adwords</p>
                        </Col>
                    </Row>
                    
                </Container>

            </Container>

            <CallToAction />

        </>
    );
};