import React, { useEffect } from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import adv from '../../assets/homemedia/social_adv.png';
import ecommerce from '../../assets/homemedia/ecommerce.png';
import comunication from '../../assets/homemedia/comunication.png';
import lead from '../../assets/homemedia/lead.png';


export default function Homemedia() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid style={{ marginTop: 70 }} className='p-0'>
                <Container fluid='md' className='pt-5 mb-5 text-center'>
                    <h1 style={{ 'fontWeight': '900' }}><span style={{ 'color': '#AFF24B' }}>HOME</span>MEDIA</h1>
                    <br />
                    <p className='lead'>LÌ FUORI CI SONO MIGLIAIA DI POTENZIALI CLIENTI. NOI TI AIUTIAMO A RAGGIUNGERLI TUTTI.</p>
                    <p>Dal social media management allo spot radio; dall’e-commerce alla lead generation: tutto ciò di cui la tua realtà ha bisogno per spiccare il volo, lo trovi qui.
                        Comunicare al meglio la propria realtà è importante.
                        E in questo periodo storico, lo è ancora di più.
                        Il Covid-19, infatti, ha dimostrato a tutti noi quanto sia fondamentale raggiungere il cliente ovunque si trovi, anche lontano dal punto vendita. Quindi, social media management, lead generation, e-commerce, spot radio, ecc. diventano essenziali. E lo saranno sempre di più.
                    </p>
                </Container>
            </Container>

            <Container fluid>
                <Container fluid='md' style={{ marginTop: 70 }}>
                    <div className='mb-5'>
                        <center>
                            <table className='table-homemedia'>
                                <tbody>
                                    <tr>
                                        <td><h1 className="green">3,6 M</h1><p>Budget investito<br />in advertising</p></td>
                                        <td><h1 className="green">+ 20</h1><p>Talenti al tuo servizio</p></td>
                                        <td><h1 className="green">2</h1><p>Sedi operative:<br />Milano e Treviso</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </center>
                    </div>

                    <h1 className='text-center'>I nostri servizi</h1>

                    <Row className='align-items-center mt-5 mb-5'>
                        <Col xs={12} lg={6}>
                            <h2>SOCIAL ADVERTISING</h2>
                            <p><b>Aumenta le vendite e acquisisci nuovi clienti attraverso campagne social targettizzate e ottimizzate.</b>
                                <br />
                                Il team di Home Media cura giornalmente i canali social di numerose attività locali e PMI, portando a casa importanti risultati in termini di conversione ed engagement.</p>
                            <div className="tag--list--homemedia">
                                <span className="tag--item">instagram</span>
                                <span className="tag--item">facebook</span>
                                <span className="tag--item">tik tok</span>
                                <span className="tag--item">pinterest</span>
                                <span className="tag--item">linkedin</span>
                                <span className="tag--item">wechat</span>
                                <span className="tag--item">twitch</span>
                                <span className="tag--item">youtube</span>
                                <span className="tag--item">twitter</span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Image src={adv} width='100%' />
                        </Col>
                    </Row>

                    <Row className='align-items-center mt-5 mb-5'>
                        <Col xs={12} lg={6}>
                            <h2>LEAD GENERATION</h2>
                            <p><b>Fai impennare il numero di clienti attraverso campagne di lead generation.</b>
                                <br />
                                Home Media supporta quotidianamente numerossissime attività locali portandole ad acquisire nuovi clienti grazie a una comunicazione mirata ed efficace. Cosa ci serve per partire ed operare al meglio? Solamente il tuo target. A misurare i risultati e a generare decine e decine di richieste da parte di nuovi potenziali clienti, pensiamo noi.</p>
                            <div className="tag--list--homemedia">
                                <span className="tag--item">sem</span>
                                <span className="tag--item">seo</span>
                                <span className="tag--item">email marketing</span>
                                <span className="tag--item">funnel marketing</span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Image src={lead} width='100%' />
                        </Col>
                    </Row>

                    <Row className='align-items-center mt-5 mb-5'>
                        <Col xs={12} lg={6}>
                            <h2>E-COMMERCE</h2>
                            <p><b>Entra in rete e comincia a vendere i tuoi prodotti online.</b>
                                <br />
                                Ti aiutiamo ad analizzare il mercato e costruiamo su misura il tuo portale e-commerce. Il tutto attraverso il nostro CMS proprietario “Shoppy”, integrabile ai principali marketplace quali eBay, Amazon, TMall, etc.</p>
                            <div className="tag--list--homemedia">
                                <span className="tag--item">ebay</span>
                                <span className="tag--item">amazon</span>
                                <span className="tag--item">alibaba</span>
                                <span className="tag--item">eprice</span>
                                <span className="tag--item">google shopping</span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Image src={ecommerce} width='100%' />
                        </Col>
                    </Row>

                    <Row className='align-items-center mt-5 mb-5'>
                        <Col xs={12} lg={6}>
                            <h2>LA COMUNICAZIONE CREATIVA</h2>
                            <p><b>Racconta il tuo business in modo originale ed efficace.</b>
                                <br />
                                Dal logo al naming, dallo spot video allo spot radio, dal flyer alla brochure: Home Media ti è in grado di fornire tutto quello che serve per dar voce alla tua attività.</p>
                            <div className="tag--list--homemedia">
                                <span className="tag--item">spot video</span>
                                <span className="tag--item">spot radio</span>
                                <span className="tag--item">brochure</span>
                                <span className="tag--item">branding</span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Image src={comunication} width='100%' />
                        </Col>
                    </Row>

                    <div className='mb-5 mt-5'>
                        <center>
                            <h1>Attività di <span style={{ 'color': '#AFF24B' }}>business</span></h1>
                            <p className='lead'>Siamo strutturati per soddisfare al meglio le esigenze di diverse tipologie di business. Nello specifico:</p>

                            <div className="tag--list--homemedia">
                                <span className="tag--item">Agricoltura e alimentazione</span>
                                <span className="tag--item">Carta, stampa, editoria</span>
                                <span className="tag--item">Commercio all'ingrosso e retail</span>
                                <span className="tag--item">Costruzioni</span>
                                <span className="tag--item">Elettricità, elettronica, ottica</span>
                                <span className="tag--item">Energia, ambiente</span>
                                <span className="tag--item">Educazione e formazione</span>
                                <span className="tag--item">Organizzazione</span>
                                <span className="tag--item">Minerali</span>
                                <span className="tag--item">Informatica, Internet</span>
                                <span className="tag--item">Ricerca e sviluppo</span>
                                <span className="tag--item">Metalli, macchinari e ingegneria	</span>
                                <span className="tag--item">Servizi alle imprese</span>
                                <span className="tag--item">Prodotti chimici</span>
                                <span className="tag--item">Prodotti farmaceutici</span>
                                <span className="tag--item">Materie plastiche</span>
                                <span className="tag--item">Tempo libero e turismo</span>
                                <span className="tag--item">Trasporti e logistica</span>
                                <span className="tag--item">Tessili, abbigliamento, cuoio</span>
                                <span className="tag--item">Orologeria, gioielleria</span>
                            </div>
                        </center>
                    </div>
                </Container>
            </Container>
        </>
    );
};