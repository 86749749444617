import React from 'react';
import { Container, Image } from 'react-bootstrap';
import dataInfrastructure from '../../assets/analytica/data-infrastructure.svg';
import dataMining from '../../assets/analytica/data-mining.svg';
import dataVisualization from '../../assets/analytica/data-visualization.svg';
import bg from '../../assets/analytica/bg.jpeg';

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { analyticaParticles } from '../../effects/particles';


export default function Analytica() {

    const particlesInit = async (engine) => {
        console.log('loaded')
        await loadFull(engine);
    }

    return (
        <>
            <Container fluid style={{ marginTop: 70 }} className='p-0'>
                <div id='slide-bg-analytica' style={{ backgroundImage: `url(${bg})` }}>
                    <Particles
                        options={analyticaParticles}
                        id="analytica-particles"
                        init={particlesInit}
                    />
                    <Container id='slide-analytica-content'>
                        <h1>Yec Analytica.<br />Una società di Data Intelligence.</h1>
                    </Container>
                </div>
            </Container>

            <Container fluid>
                <Container fluid='md' className='mt-5 mb-5' id="analytica">

                    <h2>Software su misura e consulenza specializzata sull'analisi dei dati.</h2>
                    <p>
                        Yec Analytica è una società innovativa che ha l’obiettivo di aiutare le aziende nell’estrarre conoscenza dai dati. Grazie ad un team multidisciplinare formato da ingegneri, data scientist e ricercatori, siamo in grado di offrire supporto e competenze nei seguenti tre ambiti: Data Mining, Data visualization e Big Data Infrastructure.
                    </p>
                    <p>
                        Gestiamo quotidianamente progetti di ricerca complessi e innovativi che si concretizzano nello sviluppo di software o applicativi personalizzati o che si mantengono ad un approccio consulenziale volto all’efficentamento dei processi e delle organizzazioni.
                    </p>

                    <div className='mt-5 mb-5'>
                        <h5>01 Introduzione a YEC Analytica</h5>
                        <h2>I nostri servizi</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Image src={dataMining} width={'60'} height={'60'} className='mb-3' />
                                        <h5>Data Mining</h5>
                                        <p>Analizziamo dati provenienti da diverse fonti e individuiamo informazioni strategiche.</p>
                                    </td>
                                    <td>
                                        <Image src={dataVisualization} width={'60'} height={'60'} className='mb-3' />
                                        <h5>Data Visualization</h5>
                                        <p>Sviluppiamo dashboard, report e tool per permettervi di visualizzare graficamente i vostri dati.</p>
                                    </td>
                                    <td>
                                        <Image src={dataInfrastructure} width={'60'} height={'60'} className='mb-3' />
                                        <h5>Big Data Infrastructure</h5>
                                        <p>Costruiamo archivi informatici in modo da rendere sempre accessibili e protetti i vostri dati.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-5 mb-5">
                        <h5>02 Team di YEC Analytica</h5>
                        <h2>Chi siamo</h2>
                        <p>YEC Analytica è composta da un team multidiscliplinare di data scientist,
                            ingegneri e ricercatori che progettano e sviluppano soluzioni volte
                            all’estrapolazione di conoscenza dai dati.</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5>METODO</h5>
                                        <p>Utilizziamo principi metodologici basati sul metodo scientifico.</p>
                                    </td>
                                    <td>
                                        <h5>TECNOLOGIA</h5>
                                        <p>Abbiamo sviluppato Circuit, software che consente di acquisire, manipolare e rendere accessibili grandi moli di dati.</p>
                                    </td>
                                    <td>
                                        <h5>COMPETENZE</h5>
                                        <p>Le nostre competenze trasversali consentono di adattarci alle specifiche esigenze di ciascuna azienda.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-5 mb-5">
                        <h5>03 Valori di YEC Analytica</h5>
                        <h2>I nostri valori</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5>PEOPLE</h5>
                                        <p>Le persone vengono <br /> prima di tutto </p>
                                    </td>
                                    <td>
                                        <h5>SEMPLICITY</h5>
                                        <p>Distinguiamo l'essenziale <br /> dal superfluo </p>
                                    </td>
                                    <td>
                                        <h5>TRUST</h5>
                                        <p>La nostra stretta di mano<br /> vale più di mille parole </p>
                                    </td>
                                    <td>
                                        <h5>SYMBIOSIS</h5>
                                        <p>Aggiungiamo valore a <br /> tutte le parti in gioco </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className="mt-5 mb-5">
                        <h5>04 Settori di YEC Analytica</h5>
                        <h2>Settori in cui operiamo</h2>
                        <p>Il nostro team ha l’esperienza e la capacità per la gestione di progetti in ciascuna delle aree sotto riportate.</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <ul>
                                            <li>Banking &amp; Finance</li>
                                            <li>Consultancy &amp; Strategy</li>
                                            <li>Digital &amp; New Media</li>
                                            <li>Engineering &amp; Manufacturing</li>
                                            <li>Fashion</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>Finance &amp; Accounting</li>
                                            <li>Health care</li>
                                            <li>Human Resources</li>
                                            <li>Information Technology</li>
                                            <li>Insurance</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>Marketing</li>
                                            <li>Oil &amp; Gas</li>
                                            <li>Procurement &amp; Supply Chain</li>
                                            <li>Property &amp; Construction</li>
                                            <li>Sales &amp; Retail</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Container>
            </Container>
        </>
    );
};