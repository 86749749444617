import React, { useRef } from 'react';
import { Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import billy from '../../assets/reviews/billy.png';
import dd from '../../assets/reviews/dd.png';
import moss from '../../assets/reviews/moss.png';
import spediporto from '../../assets/reviews/spediporto.png';
import zpadel from '../../assets/reviews/zpadel.png';
import unifarco from '../../assets/reviews/unifarco.png';
import vgm from '../../assets/reviews/vgm.png';
import nshot from '../../assets/reviews/nshot.png';
import eet from '../../assets/reviews/eet.png';


import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay, EffectCreative } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function Reviews() {

    const prevRef = useRef(null);
    const nextRef = useRef(null);


    return (
        <Container fluid className='p-0'>
            <div className='mb-5' id='reviews'>
                <div id='reviews-container'>

                    <div id="title-with-arrows">
                        <h2>Parola al cliente</h2>
                        <div id="swiper-navigation-top">
                            <div className='btn-icon' ref={prevRef} id='swiper-left'>
                                <FontAwesomeIcon size={'lg'} icon="chevron-left" />
                            </div>
                            <div className='btn-icon' ref={nextRef}>
                                <FontAwesomeIcon size={'lg'} icon="chevron-right" />
                            </div>
                        </div>
                    </div>

                    <div className='swiperReviews'>
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, Autoplay, EffectCreative]}
                            grabCursor={true}
                            spaceBetween={0}
                            slidesPerView={1}
                            autoplay={false}
                            navigation={false}
                            pagination={false}
                            scrollbar={false} //scrollbar={{ draggable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            //onSlideChange={() => console.log('slide change')}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                        >
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={moss} width={100} height={100} />
                                                <h3>Franco Papa</h3>
                                                <p>
                                                    <span className='small'><i>Amministratore delegato</i></span>
                                                    <br/>
                                                    MOSS S.p.A.
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    Realtà giovane, dinamica e all’avanguardia che offre svariati servizi nell’ambito dello sviluppo digitale.<br />
                                                    È stato un piacere conoscere e lavorare con il CEO Simone Dal Pont e con tutto il suo Team formato da persone altamente qualificate e molto professionali.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={spediporto} width={100} height={100} />
                                                <h3>Giampaolo Botta</h3>
                                                <p>
                                                    <span className='small'><i>General Manager</i></span>
                                                    <br/>
                                                    Spediporto
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    L’esperienza di collaborazione è partita nel 2019 con un progetto impegnativo e ambizioso che necessitava di un team preparato e competente, in grado di soddisfare prontamente tutte le nostre esigenze.
                                                    <br />Con grande soddisfazione abbiamo riscontrato in GRUPPO YEC tutti i requisiti indispensabili facendoci ritenere pienamente soddisfatti del lavoro finora ottenuto.
                                                    <br />Il Team YEC si è distinto per professionalità e grande passione nel lavoro svolto pertanto siamo certi che la nostra collaborazione proseguirà per nuovi progetti futuri.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={unifarco} width={100} height={100} />
                                                <h3>Andrea Baratto</h3>
                                                <p>
                                                    <span className='small'><i>Global Brand & Marketing Manager</i></span>
                                                    <br/>
                                                    Unifarco
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    Competenti, affidabili e super efficienti! Hanno ascoltato tutte le nostre richieste, fornendoci i giusti consigli
                                                    e supportandoci nello sviluppo di un progetto per noi fondamentale.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={dd} width={100} height={100} />
                                                <h3>Andrea Cioffi</h3>
                                                <p>
                                                    <span className='small'><i>Amministratore delegato</i></span>
                                                    <br/>
                                                    Digital Dictionary
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    Sono approdato a GRUPPO YEC dopo tante esperienze sia con freelance che con altri fornitori. Gli ho scelti a valle di una rigida selezione.
                                                    A quasi due anni di collaborazione la scelta è stata vincente visto che ci seguono nello sviluppo di soluzioni nevralgiche per la mia impresa.<br />
                                                    Perché gli ho scelti? Hanno il giusto set di competenze per seguirti nello sviluppo di progetti complessi, hanno gusto estetico (non scontato),
                                                    sono puntuali e quando sbagliano lo ammettono e sanno correggere il tiro velocemente.<br />
                                                    Difetti? Costano di più rispetto ad altri ma la qualità costa. Li consiglio per progetti sfidanti, non per piccole progettualità (siti e blog).
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={eet} width={100} height={100} />
                                                <h3>Asia Pittana</h3>
                                                <p>
                                                    <span className='small'><i>Head of Business Development</i></span>
                                                    <br/>
                                                    EET Solare
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                Siamo molto soddisfatti della collaborazione con Gruppo Yec, si sono dimostrati professionali ed efficienti. Il team è sempre stato molto competente e preparato, propositivo e con un’ottima comunicazione; hanno saputo trasformare le nostre idee in risultati, seguendoci e consigliandoci passo passo nella nostra strategia di marketing.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={zpadel} width={100} height={100} />
                                                <h3>Stefano Romor</h3>
                                                <p>
                                                    <span className='small'><i>Partner</i></span>
                                                    <br/>
                                                    Zeta Padel Club
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    Zeta Padel Club è una start up innovativa che ha sempre attribuito alla comunicazione una grande importanza. Oggi, dopo un anno dal lancio,
                                                    il nostro brand ha raggiunto un livello di notorietà impensabile in così poco tempo grazie anche al GRUPPO YEC che ha progettato e realizzato
                                                    un sito, zpadelclub.com , particolarmente curato, chiaro ed incisivo. Infine, un team molto efficiente e professionale anche in fase di manutenzione ed aggiornamento.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={vgm} width={100} height={100} />
                                                <h3>Erika Alberti</h3>
                                                <p>
                                                    <span className='small'><i>Operation Manager</i></span>
                                                    <br/>
                                                    Consorzio VGM
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    Dal 2019 ci affidiamo a GRUPPO YEC per lo sviluppo e la gestione di un progetto impegnativo e ambizioso. Il team si è dimostrato
                                                    competente e sempre presente, e ha portato a termine al meglio le nostre richieste. Per questo possiamo definirci pienamente soddisfatti
                                                    del lavoro finora ottenuto.<br />
                                                    Data la professionalità e la grande passione dimostrate, confidiamo in ulteriori proficue collaborazioni future.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={billy} width={100} height={100} />
                                                <h3>Billy Costacurta</h3>
                                                <p>
                                                    <span className='small'><i>Opinionista Sky</i></span>
                                                    <br/>
                                                    Ex difensore del Milan e Nazionale
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    La collaborazione con il Team YEC è partita a fine 2020 e l’intenzione mia e dei miei agenti era di raggiungere numeri e consapevolezza
                                                    maggiori in un progetto che precedentemente non riusciva a decollare. Capacità, fantasia e competenze sono state le qualità riscontrate
                                                    nel Team YEC che mi hanno convinto a proseguire il rapporto professionale con loro.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                            <SwiperSlide>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="1">
                                                <Image src={nshot} width={100} height={100} />
                                                <h3>Francesco Volpe</h3>
                                                <p>
                                                    <span className='small'><i>Partner</i></span>
                                                    <br/>
                                                    Nshot
                                                </p>
                                            </td>
                                            <td colSpan="2">
                                                <blockquote>
                                                    Ci siamo avvicinati a GRUPPO YEC con lo scopo di dare una spinta al nostro brand attraverso scelte di marketing oculate, pensate appositamente
                                                    per la nostra realtà. E si è rivelata da subito una scelta vincente. Il team si è dimostrato molto disponibile e pronto ad ascoltare e soddisfare
                                                    le nostre esigenze, con gli occhi sempre ben puntati ad azioni che potessero portare a risultati concreti. Stiamo costruendo insieme il
                                                    nostro e-commerce attraverso strumenti e tecnologie che la nostra azienda non aveva mai conosciuto prima. Affidandoci alla cura costante
                                                    del CEO Simone Dal Pont e del suo Team pianifichiamo assieme a GRUPPO YEC tutte le fasi di sviluppo della nostra piattaforma web e la gestione marketing.
                                                </blockquote>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SwiperSlide>
                        </Swiper>

                    </div>
                </div>
            </div>
        </Container>
    );
};