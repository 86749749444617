import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';


export default function Thanks() {

    useEffect(() => {

    }, []);


    return (
        <Container fluid style={{ marginTop: 70 }} className='dark-slide'>
            <Container fluid='md' className='pt-5 pb-5 text-center text-white'>
                
                <h2>Grazie per averci contattato!</h2>
                <p>Ti risponderemo il prima possibile.</p>
                <br />
                <Link to="/">
                    <span className='button light'>
                        Torna alla home
                    </span>
                </Link>

            </Container>
        </Container>
    );
};