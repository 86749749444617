import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo.svg';
import bcorp from '../../assets/bcorp-footer.jpg';


export default function FooterBlock() {

    return (
        <>
            <footer id='footer'>
                <Container fluid='lg' className='text-start'>
                    <Row className='mb-5'>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6>Sitemap</h6>
                            <ul>
                                <li>
                                    <Link to="/">
                                        Chi siamo
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/showcase">
                                        Progetti
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog/1">
                                        News
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contatti
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6>Servizi</h6>
                            <ul>
                                <li>
                                    <Link to="/service/web">
                                        Web
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/service/app">
                                        Applicazioni
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/service/erp">
                                        Gestionali
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/service/ecommerce">
                                        E-commerce
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6>Contatti</h6>
                            <p><a href='mailto:info@gruppoyec.com'>info@gruppoyec.com</a></p>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <Image src={bcorp} width={260} style={{ maxWidth: '100%' }} />
                        </Col>
                    </Row>
                    <hr />
                    <Row className='mb-5'>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6 style={{ color: 'rgb(65, 139, 255)' }}>Milano</h6>
                            <p className='text-muted'>Corso di Porta Vittoria, 29</p>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6 style={{ color: 'rgb(65, 139, 255)' }}>Conegliano</h6>
                            <p className='text-muted'>Viale Italia, 290/C</p>
                        </Col>
                        <Col xs={6} md={3} className='footer-block'>
                            <h6 style={{ color: 'rgb(65, 139, 255)' }}>Genova</h6>
                            <p className='text-muted'>Piazza Martinez, 7</p>
                        </Col>
                    </Row>
                    <hr />
                    <p className='small'>
                        <Link to="/">
                            <Image src={logo} alt='Logo of the site' width={120} height={50} />
                        </Link>
                        <br />
                        <b>GRUPPO YEC S.R.L. SOCIETÀ BENEFIT</b>
                        <br/>
                        Corso di Porta Vittoria 29, 20122, Milan, Italy | VAT, tax code, and number of registration with the Milan Monza Brianza Lodi Company Register 01835450493 | REA number MI 2087730
                        <br/>
                        <a href="https://www.iubenda.com/privacy-policy/83067679" target="_blank">Privacy Policy</a> & <a href="https://www.iubenda.com/privacy-policy/83067679/cookie-policy" target="_blank">Cookie Policy</a>
                        <br/>
                    </p>
                    <p className='text-end'>
                        <a target='_blank' href='https://it-it.facebook.com/gruppoyec'>
                            <FontAwesomeIcon size={'lg'} icon={["fab", "facebook"]} className='me-3' />
                        </a>
                        <a target='_blank' href='https://it.linkedin.com/company/young-endeavor-consulting'>
                            <FontAwesomeIcon size={'lg'} icon={["fab", "linkedin"]} className='me-3' />
                        </a>
                        <a target='_blank' href='https://www.instagram.com/gruppoyec/?hl=it'>
                            <FontAwesomeIcon size={'lg'} icon={["fab", "instagram"]} className='me-3' />
                        </a>
                    </p>
                    
                </Container>
            </footer>
        </>
    );
};