import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import { language } from '../../core/language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function CallToAction() {


    return (
        <Container fluid className='pt-5 pb-5 mb-0' style={{backgroundColor: '#eee'}}>
            <Container fluid='xs' className='call-to-action text-center'>
                <h2>{language.callToAction.title}</h2>
                <p className='lead'>{language.callToAction.subtitle}</p>
                <Link to="/contact">
                    <span className='button mt-3'>
                        {language.callToAction.button} <FontAwesomeIcon size={'lg'} icon="arrow-right" className='ms-2' />
                    </span>
                </Link>
            </Container>
        </Container>
    );
};