export const seo = {
    default: {
        title: 'About Us | Gruppo Yec',
        description: ''
    },
    '/services': {
        title: 'Servizi | Gruppo Yec',
        description: ''
    },
    '/service/erp': {
        title: 'Erp | Gruppo Yec',
        description: ''
    },
    '/service/ecommerce': {
        title: 'Ecommerce | Gruppo Yec',
        description: ''
    },
    '/service/ecommerce-b2c': {
        title: 'Ecommerce B2C | Gruppo Yec',
        description: ''
    },
    '/service/ecommerce-b2b': {
        title: 'Ecommerce B2B | Gruppo Yec',
        description: ''
    },
    '/service/web-and-app': {
        title: 'Web & App | Gruppo Yec',
        description: ''
    },
    '/service/app': {
        title: 'Applicazioni | Gruppo Yec',
        description: ''
    },
    '/service/siti-corporate': {
        title: 'Siti corporate | Gruppo Yec',
        description: ''
    },
    '/service/piattaforme-web': {
        title: 'Piattaforme Web | Gruppo Yec',
        description: ''
    },
    '/manifesto': {
        title: 'Manifesto | Gruppo Yec',
        description: ''
    },
    '/sociale': {
        title: 'Per il Sociale | Gruppo Yec',
        description: ''
    },
    'blog': {
        title: 'News | Gruppo Yec',
        description: ''
    },
    '/contact': {
        title: 'Contatti | Gruppo Yec',
        description: ''
    },
    '/careers': {
        title: 'Careers | Gruppo Yec',
        description: ''
    },
    '/method': {
        title: 'Metodo | Gruppo Yec',
        description: ''
    },
    '/showcase': {
        title: 'Showcase | Gruppo Yec',
        description: ''
    },
    '/company/formafarma': {
        title: 'Forma Farma | Gruppo Yec',
        description: ''
    },
    '/company/analytica': {
        title: 'Analytica | Gruppo Yec',
        description: ''
    },
    '/company/homemedia': {
        title: 'Home Media | Gruppo Yec',
        description: ''
    },
    '/company/bohtech': {
        title: 'Boh Tech | Gruppo Yec',
        description: ''
    }
    
};