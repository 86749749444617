import { useState } from 'react';
import { decode } from 'html-entities';
import { Button, Alert } from 'react-bootstrap';
import { language } from '../../core/language';

const NewsletterForm = ({ status, message, onValidated }) => {

    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);

    /**
     * Handle form submit.
     *
     * @return {{value}|*|boolean|null}
     */
    const handleFormSubmit = () => {

        setError(null);

        if (!email) {
            setError('Please enter a valid email address');
            return null;
        }

        const isFormValidated = onValidated({ EMAIL: email });

        // On success return true
        return email && email.indexOf("@") > -1 && isFormValidated;
    }

    /**
     * Handle Input Key Event.
     *
     * @param event
     */
    const handleInputKeyEvent = (event) => {
        setError(null);
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            handleFormSubmit();
        }
    }

    /**
     * Extract message from string.
     *
     * @param {String} message
     * @return {null|*}
     */
    const getMessage = (message) => {
        if (!message) {
            return null;
        }
        const result = message?.split('-') ?? null;
        if ("0" !== result?.[0]?.trim()) {
            return decode(message);
        }
        const formattedMessage = result?.[1]?.trim() ?? null;
        return formattedMessage ? decode(formattedMessage) : null;
    }

    return (
        <>
            <div className="mc-field-group">
                <input
                    onChange={(event) => setEmail(event?.target?.value ?? '')}
                    type="text"
                    onKeyUp={(event) => handleInputKeyEvent(event)}
                    placeholder={language.contact.email}
                    autoComplete="off"
                />
            </div>
            <Button className='w-100 mt-3' size='lg' variant='light' onClick={handleFormSubmit}>{language.contact.signin}</Button>
            
            <div className="newsletter-form-info">
                {status === "sending" && <div>Sending...</div>}
                {status === "error" || error ? (
                    <Alert variant='danger' className='mt-3'>
                        <span dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}></span>
                    </Alert>
                ) : null}
                {status === "success" && status !== "error" && !error && (
                    <Alert variant='success' className='mt-3'>
                        <span dangerouslySetInnerHTML={{ __html: decode(message) }}></span>
                    </Alert>
                )}
            </div>
        </>
    );
}

export default NewsletterForm