import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Image, Table } from 'react-bootstrap';
import logo from '../../assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function HeaderBlock() {

    const [menuClass, setMenuClass] = useState('')
    const [page, setPage] = useState('')

    function menuToggle() {
        if (menuClass === "") {
            setMenuClass("menu--active")
        }
        else {
            setMenuClass("")
        }
    }



    let location = useLocation();

    useEffect(() => {
        console.log('location: ', location)
        setMenuClass("")
        let location_page = location.pathname; //location.pathname.split('/').reverse()[0];
        setPage(location_page)
    }, [location]);


    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);


    return (
        <>
            <header className={menuClass}>
                <Container fluid='lg' style={{position: 'relative'}}>
                    <div className='logo'>
                        <Link to="/">
                            <Image src={logo} alt='Logo of the site' width={120} height={70} />
                        </Link>
                    </div>
                    <span id='burger--menu' onClick={menuToggle}></span>
                    <div className='menu'>
                        <ul>
                            <li className={(page === '/service/web' ? 'active' : '') + ' li-primary'}>
                                <Link to="/service/web">
                                    Web
                                </Link>
                            </li>
                            <li className={(page === '/service/app' ? 'active' : '') + ' li-primary'}>
                                <Link to="/service/app">
                                    Applicazioni
                                </Link>
                            </li>
                            <li className={(page === '/service/erp' ? 'active' : '') + ' li-primary'}>
                                <Link to="/service/erp">
                                    Gestionali
                                </Link>
                            </li>
                            <li className={(page === '/service/ecommerce' ? 'active' : '') + ' li-primary'}>
                                <Link to="/service/ecommerce">
                                    E-commerce
                                </Link>
                            </li>
                            <li className={(page === '/showcase' ? 'active' : '') + ' li-primary'}>
                                <Link to="/showcase">
                                    Progetti
                                </Link>
                            </li>
                            <li className={'li-primary'}>
                                <a href='#'>
                                    Il Gruppo<FontAwesomeIcon size={'xs'} icon={"chevron-down"} className='ms-1' />
                                </a>
                                
                                <span className='submenu'>
                                    <span className={(page === '/manifesto' ? 'active' : '') + ' li-secondary'}>
                                        <Link to="/manifesto">
                                            Manifesto
                                        </Link>
                                    </span>
                                    <span className={(page === '/sociale' ? 'active' : '') + ' li-secondary'}>
                                        <Link to="/sociale">
                                            Per il sociale
                                        </Link>
                                    </span>
                                    <span className={(page === '/method' ? 'active' : '') + ' li-secondary'}>
                                        <Link to="/method">
                                            Metodo
                                        </Link>
                                    </span>
                                    <span className={(page === '/careers' ? 'active' : '') + ' li-secondary'}>
                                        <Link to="/careers">
                                            Careers
                                        </Link>
                                    </span>
                                </span>
                            </li>
                            <li className={(page === '/contact' ? 'active' : '') + ' li-primary'}>
                                <Link to="/contact">
                                    Contatti
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Container>
            </header>
        </>
    );
};