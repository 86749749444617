import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import CallToAction from '../../components/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default function App() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h1><span style={{ color: '#418BFF' }}>Sviluppiamo da zero</span> la tua nuova APP mobile <span style={{ color: '#418BFF' }}>iOS e Android</span>.</h1>
                    <p className='lead text-muted m-auto' style={{maxWidth: 700}}>
                        Pianifichiamo e supportiamo tutte le fasi del processo: dalla strategia allo sviluppo IT, dalla gestione alle integrazioni, dagli aspetti legali e fiscali alla logistica.
                    </p>
                </Container>
            </Container>


            <Container fluid className='ps-0 pe-0 pt-5 pb-5' style={{backgroundColor: '#eee'}}>
                <Container fluid='md' className='pt-5 pb-5 text-start'>
                    <h2>Siamo tra le poche realtà italiane a conoscere e a saper sviluppare APP ottimizzate e performanti.</h2>
                    <p className='lead'>Per questo motivo abbiamo aperto una società indipendente totalmente dedicata allo sviluppo di applicazioni mobile.</p>

                    <hr className='mt-5 mb-5' />

                    <h2>Curiamo le performance della tua APP.</h2>
                    <p className='lead'>Curiamo e gestiamo con grande professionalità le attività di ottimizzazione sugli app store (ASO) e prestiamo grande attenzione
                    ai continui aggiornamenti di protocolli e librerie.</p>

                    <hr className='mt-5 mb-5' />

                    <h2>Guidiamo il Cliente e suggeriamo modelli di revenue.</h2>
                    <p className='lead'>Esistono molteplici tipologie di monetizzazione in APP. È importante conoscerle tutte per poter suggerire il giusto approccio
                    a seconda del progetto di business.</p>
                </Container>
            </Container>


            <Container fluid style={{ backgroundColor: '#1A2446' }}>
                <Container fluid='md' className='pt-4 pb-3'>
                    <Row className='mt-5'>
                        <Col xs={12} lg={4} className='pe-5 pb-4 text-white'>
                            <h2>Un metodo firmato<br/><span style={{ color: '#418BFF' }}>GRUPPO YEC</span></h2>
                        </Col>
                        <Col xs={12} lg={8} className='pe-5 pb-4 text-white'>
                            <Row>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Assessment <sup className='ms-1 small text-secondary'><span className='small'>01</span></sup></h3>
                                    <p className='small'>
                                        L’Assessment è un’analisi approfondita in cui vengono toccati tutti gli aspetti chiave per lo sviluppo della tua APP mobile: dall’analisi del branding, alla definizione precisa della struttura del nuovo progetto.
                                        <br/>Frutto di anni di esperienza sul campo, il nostro metodo è pensato per gettare le migliori basi su cui poggerà ogni progetto e costruire insieme al cliente un percorso chiaro e strutturato, lungo il quale non verrà mai lasciato solo. Anzi, sarà reso partecipe di ogni singola scelta, dalla più “banale” a quella più determinante. 
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Design <sup className='ms-1 small text-secondary'><span className='small'>02</span></sup></h3>
                                    <p className='small'>
                                        La UX (User Experience) e la UI (User Interface) sono due concetti fondamentali nel design di un’APP, e si riferiscono rispettivamente all'esperienza dell'utente e all'interfaccia utente. Sono strettamente collegati ma rappresentano aspetti diversi del processo di progettazione e sviluppo di un'applicazione web. In sintesi, mentre la UI riguarda principalmente l'aspetto visivo e l'interfaccia interattiva dell'applicazione, la UX si occupa dell'esperienza più ampia dell'utente durante il suo utilizzo. Entrambe sono estremamente importanti per il successo dell’APP, poiché una buona UI può attirare l'attenzione dell'utente, ma una buona UX garantisce che l'utente sia soddisfatto e motivato a continuare a utilizzare l’applicativo mobile.
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Sviluppo <sup className='ms-1 small text-secondary'><span className='small'>03</span></sup></h3>
                                    <p className='small'>
                                        Nella scelta dei linguaggi di sviluppo front end, prediligiamo soluzioni open source che, essendo frequentemente aggiornati, permettono di realizzare velocemente interfacce ottimizzate dal punto di vista tecnico, della sicurezza e delle performance.
                                        <br/>Sviluppiamo in React Native, Swift, Java e Ionic. <br/>
                                        <FontAwesomeIcon size={'lg'} icon={["fab", "swift"]} className='me-2' />
                                        <FontAwesomeIcon size={'lg'} icon={["fab", "java"]} className='me-2' />
                                        <FontAwesomeIcon size={'lg'} icon={["fab", "react"]} className='me-2' />
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Backend & Database <sup className='ms-1 small text-secondary'><span className='small'>04</span></sup></h3>
                                    <p className='small'>
                                        Per garantire i principi di affidabilità e scalabilità, utilizziamo framework di backend integrabili con API Rest e con sistemi di archiviazione e gestione dati. Adottiamo architetture a microservizi in grado di efficientare gli investimenti nel medio periodo, garantendo al tempo stesso una maggiore sicurezza, minore downtime e prestazioni ottimali.
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>System integration <sup className='ms-1 small text-secondary'><span className='small'>05</span></sup></h3>
                                    <p className='small'>
                                        La capacità di integrare sistemi e piattaforme differenti è una delle peculiarità che ha distinto il nostro percorso fin dall’inizio. Dai motori semantici per l’ottimizzazione del customer care di un’importante assicurazione europea, fino ad arrivare ai gestionali di uno dei più importanti porti italiani, abbiamo coperto tutti i livelli di complessità in molteplici settori industriali.
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>ASO: App Store Optimization <sup className='ms-1 small text-secondary'><span className='small'>06</span></sup></h3>
                                    <p className='small'>
                                        L'ASO, acronimo di App Store Optimization, è una pratica di ottimizzazione utilizzata per migliorare la visibilità e la scoperta di un'applicazione mobile all'interno degli app store, come l'App Store di Apple per dispositivi iOS e il Google Play Store per dispositivi Android. L'obiettivo principale dell'ASO è aumentare il numero di download dell'app attraverso l'ottimizzazione di vari elementi correlati all'app store.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <CallToAction />

        </>
    );
};