import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import CallToAction from '../../components/CallToAction';



export default function SitiCorporate() {

    useEffect(() => {

    }, []);


    return (
        <>
            <Container fluid style={{ marginTop: 70 }}>
                <Container fluid='md' className='pt-5 pb-5 text-center mb-5'>
                    <h1>Sviluppiamo da zero il tuo nuovo <span style={{ color: '#418BFF' }}>sito aziendale 
                    all’avanguardia</span>, prestando la massima attenzione sia al design (UX/UI) che alle tecnologie utilizzate.</h1>
                </Container>
            </Container>


            <Container fluid className='ps-0 pe-0 pt-5 pb-5' style={{backgroundColor: '#eee'}}>
                <Container fluid='md' className='pt-5 pb-5 text-start'>
                    <h2>Amiamo la semplicità.</h2>
                    <p className='lead'>Per questo sviluppiamo siti web chiari e intuitivi in modo che gli utenti riescano a navigarli con facilità e piacevolezza.</p>

                    <hr className='mt-5 mb-5' />

                    <h2>Garantiamo sicurezza.</h2>
                    <p className='lead'>Per questo prestiamo grande attenzione alla prevenzione degli attacchi informatici.</p>

                    <hr className='mt-5 mb-5' />

                    <h2>Sviluppiamo prodotti efficaci.</h2>
                    <p className='lead'>Velocità di caricamento, stabilità, pulizia del codice, sono solo alcuni degli elementi fondamentali per ottenere un buon posizionamento all’interno dei motori di ricerca.</p>
                </Container>
            </Container>


            <Container fluid style={{ backgroundColor: '#1A2446' }}>
                <Container fluid='md' className='pt-4 pb-3'>
                    <Row className='mt-5'>
                        <Col xs={12} lg={4} className='pe-5 pb-4 text-white'>
                            <h2>Un metodo firmato<br/><span style={{ color: '#418BFF' }}>GRUPPO YEC</span></h2>
                        </Col>
                        <Col xs={12} lg={8} className='pe-5 pb-4 text-white'>
                            <Row>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Assessment <sup className='ms-1 small text-secondary'><span className='small'>01</span></sup></h3>
                                    <p className='small'>
                                        L’Assessment è un’analisi approfondita in cui vengono toccati tutti gli aspetti chiave per lo sviluppo del tuo nuovo sito web: dall’analisi del branding, alla definizione precisa della struttura del nuovo progetto.
                                        <br/>Frutto di anni di esperienza sul campo, il nostro metodo è pensato per gettare le migliori basi su cui poggerà ogni progetto e costruire insieme al cliente un percorso chiaro e strutturato, lungo il quale non verrà mai lasciato solo. Anzi, sarà reso partecipe di ogni singola scelta, dalla più “banale” a quella più determinante. 
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Design <sup className='ms-1 small text-secondary'><span className='small'>02</span></sup></h3>
                                    <p className='small'>
                                        La UX (User Experience) e la UI (User Interface) sono due concetti fondamentali nel design di un progetto web, e si riferiscono rispettivamente all'esperienza dell'utente e all'interfaccia utente. Sono strettamente collegati ma rappresentano aspetti diversi del processo di progettazione e sviluppo di un sito. In sintesi, mentre la UI riguarda principalmente l'aspetto visivo e l'interfaccia interattiva del sito, la UX si occupa dell'esperienza più ampia dell'utente durante il suo utilizzo. Entrambe sono estremamente importanti per il successo di un progetto web, poiché una buona UI può attirare l'attenzione dell'utente, ma una buona UX garantisce che l'utente sia soddisfatto e motivato a continuare a utilizzare il sito web.
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Sviluppo front end <sup className='ms-1 small text-secondary'><span className='small'>03</span></sup></h3>
                                    <p className='small'>
                                        Nella scelta dei linguaggi di sviluppo front end, prediligiamo soluzioni open source che, essendo frequentemente aggiornati, permettono di realizzare interfacce ottimizzate dal punto di vista tecnico, della sicurezza e delle performance.
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>Backend & Database <sup className='ms-1 small text-secondary'><span className='small'>04</span></sup></h3>
                                    <p className='small'>
                                        Per garantire i principi di affidabilità e scalabilità, utilizziamo framework di backend integrabili con API Rest e con sistemi di archiviazione e gestione dati. Adottiamo architetture a microservizi in grado di efficientare gli investimenti nel medio periodo, garantendo al tempo stesso una maggiore sicurezza, minore downtime e prestazioni ottimali.
                                    </p>
                                </Col>
                                <Col xs={12} lg={6} className='pe-5 pb-4'>
                                    <h3>System integration <sup className='ms-1 small text-secondary'><span className='small'>05</span></sup></h3>
                                    <p className='small'>
                                        La capacità di integrare sistemi e piattaforme differenti è una delle peculiarità che ha distinto il nostro percorso fin dall’inizio. Dai motori semantici per l’ottimizzazione del customer care di un’importante assicurazione europea, fino ad arrivare ai gestionali di uno dei più importanti porti italiani, abbiamo coperto tutti i livelli di complessità in molteplici settori industriali.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <CallToAction />

        </>
    );
};