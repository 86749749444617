import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Autoplay, FreeMode } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/scrollbar';
import arrow from '../assets/images/right.gif';



export default function history() {

    return (
        <Container fluid='md' style={{ marginTop: 70 }}>

            <Container className='pt-5'>
                <Image src={arrow} width={130} height={130} />

                <div className='swiperHistory'>
                    <Swiper
                        modules={[Autoplay, Scrollbar, FreeMode]}
                        grabCursor={true}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={false}
                        autoplay={false}
                        freeMode={true}
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <h1>2017</h1>
                            <p>Un viaggio in treno, due sconosciuti e una passione in comune: quella per la tecnologia. Gruppo Yec nasce così, da un incontro fortuito tra Simone Dal Pont e Marco Cekada e dalla loro voglia di dare vita a una realtà giovane che supporti le aziende durante la fase di digital trasformation.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1>2018</h1>
                            <p>Il fatturato dell’agenzia aumenta del 5x. Viene sviluppato Circuit, un software dedicato al settore del data driven advertising, ed è dotato di innovativi algoritmi basati su logiche di “machine learning”. Gli stessi algoritmi supportano l’analisi dei big data, le tecniche statistiche di apprendimento automatico e svolgono analisi predittive.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1>2019</h1>
                            <p>Il team di Gruppo Yec si allarga con l’inserimento di nuove figure professionali. Nasce Analityca, unit che si occupa di analisi dei dati e li traduce in informazioni utili per aziende e istituzioni. Inoltre, viene sviluppato il CMS e-commerce Shoppy, un tool altamente performante che consente la creazione di piattaforme flessibili e dinamiche.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1>2020</h1>
                            <p>La crescita continua, a livello di fatturato e di team. Nasce così una unit creativa che si occupa esclusivamente di marketing e sviluppo mobile. Nell’arco di pochi mesi vengono rilasciate diverse app proprietarie, dedicate al mondo iOS.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h1>2021</h1>
                            <p>Viene inaugurata la nuova sede di Gruppo Yec nel cuore di Milano, in Corso di Porta Vittoria. L’agenzia consolida la propria crescita e lancia due nuove realtà: Formafarma, progetto tech in ambito farmaceutico; Boh Technologies, unit dedicata allo sviluppo di applicazioni mobile, native per iOS.</p>
                        </SwiperSlide>
                    </Swiper>

                </div>
            </Container>

        </Container>
    );
};