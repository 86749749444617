import React from 'react';
import { Container, Image } from 'react-bootstrap';
import logo from '../../assets/bohtech/logo.svg';
import bg from '../../assets/bohtech/bg.jpeg';

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { bohParticles } from '../../effects/particles';


export default function Bohtech() {

    const particlesInit = async (engine) => {
        console.log('loaded')
        await loadFull(engine);
    }

    return (
        <>
            <Container fluid style={{ marginTop: 70 }} className='p-0'>
                <div id='slide-bg-boh' style={{ backgroundImage: `url(${bg})` }}>
                    <Particles
                        options={bohParticles}
                        id="boh-particles"
                        init={particlesInit}
                    />
                    <Container fluid='md' id='slide-boh-content'>
                        <h1>Boh Tech.<br />We make apps.</h1>
                    </Container>
                </div>
            </Container>

            <Container fluid>
                <Container id="boh" fluid='md' className='mt-5 mb-5 pt-5 pb-5'>
                    <Image src={logo} width={'100'} height={'60'} />
                    <h2>Sviluppiamo app.<br />Di ogni tipo, forma e colore.</h2>
                    <p className="lead">Dai servizi ai giochi: trasformiamo la tua idea in un’app fatta su misura per te. Qualsiasi essa sia.</p>
                    <br />
                    <a href='https://boh-tech.com/clientsApp/' target='_blank'>
                        <span className='button'>
                            Visita il nostro sito
                        </span>
                    </a>
                </Container>
            </Container>
        </>
    );
};